<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">{{ $path('/hat/task') }}</span>
      </template>
      <template v-slot:header-right>
        <a-button :class="$style.btnSm" type="primary" @click="handleGoList"
          >返回列表</a-button
        >
      </template>
      <div slot="content" :class="$style.wrapperContent">
        <progress-map :inspectionId="inspectionId"></progress-map>
        <task-list
          @handleDetail="handleDetail"
          :inspectionId="inspectionId"
        ></task-list>
        <task-detail :inspections="detailItem" ref="taskDetail"></task-detail>
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { getPagePm } from '@/services/smart-hat/pm';
import { getPageByName } from '@/services/smart-hat/group';
import { Emitter } from '@/utils';
import { Debounce } from 'lodash-decorators';
import TaskList from './list/index.vue';
import TaskDetail from './list/detail.vue';
import moment from 'moment';
import ProgressMap from '@/views/hat/file/progress-map.vue';

/** @name 文件类型 */
export const FileTypeList = [
  {
    key: 'AUDIO',
    label: '音频',
  },
  {
    key: 'OTHER',
    label: '其它',
  },
  {
    key: 'PHOTO',
    label: '照片',
  },
  {
    key: 'VIDEO',
    label: '视频',
  },
];

/** @name 搜索事件的event_emit */
export const FILE_SEARCH_EVENT_EMIT = 'file-search-event';
/** @name 导出事件的event_emit */
export const FILE_DOWNLOAD_EVENT_EMIT = 'file-download-event';

/** @name 文件管理的tabs */
export const TABS_KEYS = {
  /** @name 全部文件 */
  ALL_FILE: 'ALL_FILE',
  /** @name 地图模式 */
  FILE_MAP_MODE: 'FILE_MAP_MODE',
};

@Component({
  components: {
    ProgressMap,
    LayoutContent,
    TaskList,
    TaskDetail,
  },
})
export default class Detail extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  detailItem;

  get inspectionId() {
    return this.detailItem.inspectionId || '';
  }

  activeKey = TABS_KEYS.ALL_FILE;
  TABS_KEYS = TABS_KEYS;

  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-2, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-6, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }

  handleTabChange() {
    this.handleReset();
  }

  mounted() {
    this.initProject();
  }

  handleSearch() {
    Emitter.$emit(FILE_SEARCH_EVENT_EMIT, this.activeKey, this.search);
  }

  async handleGoList() {
    this.$emit('backList');
  }

  @Debounce(500)
  handleDownload() {
    Emitter.$emit(FILE_DOWNLOAD_EVENT_EMIT, this.search);
  }
  handleReset() {
    this.search = {
      type: undefined,
      content: undefined,
      dateRange: [],
      projectId: undefined,
      groupId: undefined,
    };
    Emitter.$emit(FILE_SEARCH_EVENT_EMIT, this.activeKey, this.search);
  }

  search = {
    type: undefined,
    content: undefined,
    dateRange: [],
    projectId: undefined,
    groupId: undefined,
  };

  typeList = FileTypeList;

  projects = [];
  async initProject(search) {
    let params = {};
    if (search) {
      params.input = search;
    } else {
      delete params.input;
    }
    const { records } = await getPagePm(params);
    const arr = [
      {
        pmName: '全部',
        pkId: '',
      },
      {
        pmName: '未加入项目',
        pkId: 'NOT_JOIN',
      },
    ];
    this.projects = arr.concat(records);
  }
  async handleProjectSearch(search) {
    await this.initProject(search);
  }
  groups = [];
  async handleProjectChange(projectId, search) {
    let params = {};
    if (projectId === '') {
      params = {};
    } else {
      params = {
        projectId: projectId,
      };
    }
    if (typeof search === 'string') {
      if (search) {
        params.input = search;
      } else {
        delete params.input;
      }
    }
    const { records } = await getPageByName(params);
    const arr = [
      {
        deviceGroupName: '未加入编组',
        pkId: 'NOT_JOIN',
      },
    ];
    this.groups = arr.concat(records);
    this.search.deviceGroupIds = [];
  }
  async handleGroupSearch(search) {
    await this.handleProjectChange(this.search.projectId, search);
  }
  selectInputBackground = '#fff';
  isFull = false;
  handleFullScreen(flag) {
    this.isFull = flag;
  }
  handleDetail(item) {
    this.$refs.taskDetail.handleDetail(item);
  }
}
</script>
<style lang="less" module>
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  :global {
    .ant-btn-sm {
      height: 24px;
    }
  }
  .btnSm {
    margin-right: 10px;
  }
  .wrapperContent {
    position: relative;
    border-radius: 6px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .btnOther + .btnOther {
    margin-left: 10px;
  }
}
.spanDesc {
  color: var(--font-info);
  font-size: 14px;
  margin-right: 20px;
}
.formWrap {
  margin-bottom: 10px;
}
.topWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
