<template>
  <div
    :class="[$style.wrapper, { [$style.screen]: !isScreen }]"
    v-if="isShowDetail"
  >
    <div :class="$style.progressWrap">
      <div :class="$style.progress">
        <div
          :class="[
            { [$style.progressStart]: idx === 0 },
            $style.progressItem,
            { [$style.progressNoStart]: item.status === 'INIT' },
            { [$style.progressEnd]: idx === taskDetail.subList.length - 1 },
          ]"
          v-for="(item, idx) in taskDetail.subList"
          :key="idx"
          @click="handleSubTaskDetail(item)"
        >
          {{ item.taskNam }}
          <span
            v-if="item.status === 'SUCCESS'"
            :class="[$style.progressItemStatus, $style.progressItemSuccess]"
          >
            <x-icon :class="$style.icon" type="tc-icon-check" />
          </span>
          <span
            v-if="item.status === 'FAIL'"
            :class="[$style.progressItemStatus, $style.progressItemError]"
          >
            <x-icon :class="$style.icon" type="tc-icon-close" />
          </span>
        </div>

        <!--        <div :class="[$style.progressMiddle, $style.progressItem]">-->
        <!--          走道巡检-->
        <!--          <x-icon type="tc-icon-delete" />-->
        <!--        </div>-->
        <!--        <div :class="[$style.progressItem, $style.progressNoStart]">-->
        <!--          走道巡检-->
        <!--          <x-icon type="tc-icon-delete" />-->
        <!--        </div>-->
        <!--        <div :class="[$style.progressEnd, $style.progressItem]">-->
        <!--          走道巡检-->
        <!--          <x-icon type="tc-icon-delete" />-->
        <!--        </div>-->
      </div>
      <a-icon
        type="caret-down"
        :class="[$style.icon]"
        :style="isScreen ? { transform: 'rotate(-180deg)' } : ''"
        @click="handleClick"
      />
    </div>

    <div :class="$style.infoDes">
      <div>
        <div :class="$style.infoLabel">
          日期：
        </div>
        <div :class="$style.infoContent">
          {{ subTaskDetail.startTime }} - {{ subTaskDetail.endTime }}
        </div>
      </div>
      <div>
        <div :class="$style.infoLabel">
          说明
        </div>
        <div :class="$style.infoContent">
          {{ subTaskDetail.taskDesc }}
        </div>
      </div>
      <div>
        <div :class="$style.infoLabel">
          地点
        </div>
        <div :class="$style.infoContent"></div>
        {{ subTaskDetail.address }}
      </div>
      <div>
        <div :class="$style.infoLabel">
          要求
        </div>
        <div :class="$style.infoContent">
          <span :class="$style.cycleWarp">
            <span :class="$style.cycleIcons" v-if="subTaskDetail.photoNumber">
              <x-icon type="tc-icon-camera-filled" />
            </span>
            <span :class="$style.cycleIcons" v-if="subTaskDetail.videoNumber">
              <x-icon type="tc-icon-video-filled" />
            </span>
            <span :class="$style.cycleIcons" v-if="subTaskDetail.formNumber">
              <x-icon type="tc-icon-form-filled" />
            </span>
            <!--                <x-icon type="tc-icon-dingwei2" @click="handleClick" />-->
          </span>
          <!--          <span>-->
          <!--            <x-icon type="tc-icon-delete" />-->
          <!--            <x-icon type="tc-icon-delete" />-->
          <!--            <x-icon type="tc-icon-delete" />-->
          <!--            <x-icon type="tc-icon-delete" />-->
          <!--          </span>-->

          <!--          <span>-->
          <!--            <a href="https://www..baidu.com"></a>-->
          <!--            表单链接-->
          <!--          </span>-->
        </div>
      </div>
    </div>
    <div :class="$style.infoDetailWrap">
      <div>
        <span :class="$style.detailLabel">
          2024年
        </span>
        <span>
          <x-icon :class="$style.detailLabelIcon" type="tc-icon-clock" />
        </span>
      </div>

      <div :class="$style.labelWarp">
        <a-select
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          v-model="cycleId"
          @search="() => {}"
          @change="handleCycleChange"
          :class="$style.detail_filter"
        >
          <a-select-option
            v-for="item in cycleItemsOptions"
            :value="item.pkId"
            :key="item.pkId"
            >{{ item.pmName }}</a-select-option
          >
        </a-select>
      </div>

      <div v-for="(item, idx) in cycleItems" :key="idx">
        <div :class="$style.cycleTab">
          <span :class="$style.point"></span>
          <a-icon
            type="caret-down"
            :class="[$style.icon]"
            :style="item.isShow ? { transform: 'rotate(-180deg)' } : ''"
            @click="handleShow(item)"
          />
          {{ item.pmName }}
        </div>
        <template v-if="item.isShow">
          <div
            v-for="(iitem, iidx) in getSubTaskByCycle(item.pkId).dateRecordList"
            :key="iidx"
          >
            <div :class="$style.labelWarp">
              <div :class="$style.label">
                <span :class="$style.subTaskCycle">
                  <!--              <x-icon type="tc-icon-clock" @click="handleClick" />-->
                  <a-icon
                    type="caret-down"
                    :class="[$style.icon]"
                    :style="
                      iitem.isShow ? { transform: 'rotate(-180deg)' } : ''
                    "
                    @click="handleShow(iitem)"
                  />

                  {{ dayjs(iitem.date).format('MM/DD') }}
                </span>
                <span :class="$style.cyclePoint">
                  <span :class="$style.subPoint"> </span>
                </span>
                <span :class="$style.cycleWarp">
                  <span :class="$style.cycleIcons">
                    <x-icon type="tc-icon-camera-filled" />
                    <span
                      :class="[
                        $style.cycleIconStatus,
                        { [$style.success]: item.photoSuccess },
                        { [$style.error]: !item.photoSuccess },
                      ]"
                    >
                      <x-icon
                        v-if="item.photoSuccess"
                        :class="$style.icon"
                        type="tc-icon-check"
                      />
                      <x-icon
                        v-else
                        :class="$style.icon"
                        type="tc-icon-close"
                      />
                    </span>
                  </span>
                  <span :class="$style.cycleIcons">
                    <x-icon type="tc-icon-video-filled" />
                    <span
                      :class="[
                        $style.cycleIconStatus,
                        { [$style.success]: item.videoSuccess },
                        { [$style.error]: !item.videoSuccess },
                      ]"
                    >
                      <x-icon
                        v-if="item.videoSuccess"
                        :class="$style.icon"
                        type="tc-icon-check"
                      />
                      <x-icon
                        v-else
                        :class="$style.icon"
                        type="tc-icon-close"
                      />
                    </span>
                  </span>
                  <span :class="$style.cycleIcons">
                    <x-icon type="tc-icon-form-filled" />
                    <span
                      :class="[
                        $style.cycleIconStatus,
                        { [$style.success]: item.formSuccess },
                        { [$style.error]: !item.formSuccess },
                      ]"
                    >
                      <x-icon
                        v-if="item.formSuccess"
                        :class="$style.icon"
                        type="tc-icon-check"
                      />
                      <x-icon
                        v-else
                        :class="$style.icon"
                        type="tc-icon-close"
                      />
                    </span>
                  </span>
                  <span :class="$style.cycleIcons">
                    <x-icon type="tc-icon-dingwei2" />
                    <span
                      :class="[
                        $style.cycleIconStatus,
                        { [$style.success]: item.start },
                        { [$style.error]: !item.start },
                      ]"
                    >
                      <x-icon
                        v-if="item.start"
                        :class="$style.icon"
                        type="tc-icon-check"
                      />
                      <x-icon
                        v-else
                        :class="$style.icon"
                        type="tc-icon-close"
                      />
                    </span>
                  </span>
                  <!--                <x-icon type="tc-icon-dingwei2" @click="handleClick" />-->
                  <span
                    :class="[$style.cycleStatus, $style.cycleStatusSuccess]"
                    v-if="
                      item.photoSuccess &&
                        item.videoSuccess &&
                        item.formSuccess &&
                        item.start
                    "
                    >已完成</span
                  >
                  <span :class="$style.cycleStatus" v-else>未完成</span>
                </span>
              </div>
              <template v-if="iitem.isShow">
                <div
                  :class="$style.removeBtn"
                  @click="
                    () => {
                      iitem.showDelete = !iitem.showDelete;
                    }
                  "
                  v-if="!iitem.showDelete"
                >
                  移除
                </div>

                <div :class="$style.removeBtn" v-else>
                  <span
                    @click="
                      () => {
                        iitem.showDelete = !iitem.showDelete;
                      }
                    "
                  >
                    取消
                  </span>

                  <span @click="handleDelete(item, iitem)">确定</span>
                </div>
              </template>
            </div>
            <template v-if="iitem.isShow">
              <div
                v-if="
                  iitem.recordList.length &&
                    iitem.recordList.some(item => !item.isDelete)
                "
              >
                <div :class="$style.recordWarp">
                  <div
                    v-for="item in iitem.recordList"
                    :key="item.businessId"
                    :class="$style.iconWarp"
                  >
                    <template>
                      <!--                      <x-icon-->
                      <!--                        v-if="iitem.showDelete"-->
                      <!--                        :class="$style.icon"-->
                      <!--                        type="tc-icon-close"-->
                      <!--                        @click.stop="handleClose(item)"-->
                      <!--                      />-->

                      <a-checkbox
                        :class="$style.icon"
                        v-if="iitem.showDelete"
                        v-model="item.isDelete"
                      ></a-checkbox>

                      <x-oss-image
                        :class="$style.fileImg"
                        v-if="item.type === 'PHOTO'"
                        :ossPath="item.filePath"
                        basePath="/oss/iot/oss"
                        @click="handlePreview(item.filePath)"
                      />
                      <video-thumbnail
                        :class="$style.thumbnail"
                        basePath="/oss/iot/oss"
                        :ossPath="item.filePath"
                        :hasClick="false"
                        v-else-if="item.type === 'VIDEO'"
                        @click="handlePreview(item.filePath)"
                      />

                      <div
                        :class="$style.iconBox"
                        @click="handlePreview(item.filePath)"
                        v-else
                      >
                        <x-icon
                          :type="FILE_ICON[item.type]"
                          :class="$style.icon"
                        />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <EmptyContent v-else type="list" />
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import Displacement from '@/views/iot/electric-box/device-configuration/displacement.vue';
import {
  deleteBusiness,
  getPageDeviceRecord,
  getSubTaskDetail,
} from '@/services/smart-hat/task';
import dayjs from 'dayjs';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import VideoThumbnail from '@/views/hat/device/manage-model/file/video-thumbnail.vue';
import Preview from '@/views/hat/file/preview/index.vue'; // eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars

@Component({
  methods: { dayjs },
  components: { VideoThumbnail, EmptyContent, Displacement },
})
export default class TaskDetail extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  inspections;

  cycleId = null;

  FILE_ICON = {
    PHOTO: 'tc-color-file-picture',
    VIDEO: 'tc-color-file-video',
    AUDIO: 'tc-color-file-voice',
    OTHER: 'tc-color-file-unknown',
  };
  taskDetail = {};
  isShowDetail = false;
  subTaskDetail = {};
  subTaskFileDetail = {};
  cycleItems = [];
  cycleItemsOptions = [
    {
      pmName: `全部周期`,
      pkId: '0',
    },
  ];
  handleDetail(item) {
    this.isShowDetail = true;
    this.taskDetail = item;
    // this.cycleId = this.inspections.cycleInterval;
    this.cycleId = this.inspections.cycleInterval;
    this.handleSubTaskDetail(this.taskDetail.subList[0]);
  }
  async handleSubTaskDetail(item) {
    const { idxTaskId } = item;
    this.subTaskDetail = await getSubTaskDetail({
      taskId: idxTaskId,
    });
    const { records } = await getPageDeviceRecord({
      idxTaskId,
      idxDeviceId: this.taskDetail.deviceId,
      inspectionId: this.inspections.inspectionId,
    });
    this.subTaskFileDetail = records.map(item => {
      const dateList = item.dateList
        .map(iitem => {
          return {
            value: iitem,
            label: dayjs(iitem).format('MM/DD'),
          };
        })
        .sort((a, b) => a.value - b.value);
      const dateRecordList = item.dateRecordList
        .map(item => {
          return {
            ...item,
            isShow: !!item.recordList.length,
            showDelete: false,
          };
        })
        .filter(item => {
          return item;
        })
        .sort((a, b) => a.date - b.date);

      return {
        ...item,

        dateList,
        dateRecordList,
      };
    });

    this.cycleItems = this.subTaskFileDetail
      .map(item => {
        const label = item.dateList.map(item => item.label).join('，');
        return {
          pmName: `周期${item.cycleNum}（${label}）`,
          pkId: item.cycleNum,
          isShow: true,
        };
      })
      .sort((a, b) => a.pkId - b.pkId);
    this.cycleItemsOptions = [
      {
        pmName: `全部周期`,
        pkId: '0',
      },
    ];
    this.cycleItemsOptions = this.cycleItemsOptions.concat(this.cycleItems);
  }

  getSubTaskByCycle(idx) {
    return this.subTaskFileDetail.find(item => item.cycleNum === idx);
  }
  handlePreview(path) {
    Preview.createModal({
      urls: path,
      ossBasePath: '/oss/iot/oss',
    });
  }
  a = 1;
  isScreen = false;
  handleClick() {
    this.isScreen = !this.isScreen;
  }
  handleShow(item) {
    const isShow = !item.isShow;
    this.$set(item, 'isShow', isShow);
  }
  handleClose(item) {
    this.$set(item, 'isDelete', true);
  }
  async handleDelete(item, iitem) {
    const { recordList } = iitem;
    const deleteList = recordList
      .filter(item => item.isDelete)
      .map(item => {
        return {
          businessIds: item.businessId,
          type: item.type,
          idxCycleId: item.idxCycleId,
        };
      });
    //
    const types = [...new Set(deleteList.map(item => item.type))]
      .map(item => {
        return {
          businessIds: deleteList
            .filter(iitem => iitem.type === item)
            .map(item => item.businessIds),
          type: item,
        };
      })
      .filter(item => item.businessIds.length > 0);

    if (!deleteList.length) return;

    await deleteBusiness({
      businessList: types,
      cycleId: deleteList[0].idxCycleId,
    });
    await this.handleSubTaskDetail(this.taskDetail.subList[0]);

    // iitem.showDelete = !iitem.showDelete;
  }

  deleteItems = [];
  handleChange(item) {
    const hasItem = this.deleteItems.includes(item.businessId);
    if (hasItem) {
      this.deleteItems.splice(this.deleteItems.indexOf(item.businessId), 1);
    } else {
      this.deleteItems.push(item.businessId);
    }
  }

  handleCycleChange() {
    this.cycleItems = this.subTaskFileDetail
      .map(item => {
        const label = item.dateList.map(item => item.label).join('，');
        return {
          pmName: `周期${item.cycleNum}（${label}）`,
          pkId: item.cycleNum,
          isShow: true,
        };
      })
      .sort((a, b) => a.pkId - b.pkId)
      .filter(item => {
        if (this.cycleId === '0') {
          return true;
        } else {
          return item.pkId === this.cycleId;
        }
      });
  }
}
</script>
<style lang="less" module>
.screen {
  height: 500px !important;
}
.wrapper {
  transition: 0.5s;
  position: absolute;
  bottom: 10px;
  left: 366px;
  width: calc(100% - 378px);
  height: 56px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  .progressWrap {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
    .progress {
      display: flex;
      align-items: center;
      .progressItem {
        text-align: center;
        min-width: 128px;
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        color: #ffffff;
        padding: 0 20px;
        box-sizing: border-box;
        overflow-y: hidden;
        .progressItemStatus {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          color: #fff;
          .icon {
            font-size: 10px;
            font-weight: bold;
          }
        }
        .progressItemSuccess {
          background: rgba(28, 236, 22, 1) !important;
        }
        .progressItemError {
          background: rgba(246, 0, 0, 1) !important;
        }
      }
      .progressMiddle {
        background-color: #4771ff;
        clip-path: polygon(
          calc(100% - 12.5px) 0%,
          100% 50%,
          calc(100% - 12.5px) 100%,
          0% 100%,
          12.5px 12.5px,
          0% 0%
        );
      }
      .progressStart {
        border-radius: 12px 0 0 12px;
        background-color: #4771ff;
        clip-path: polygon(
          calc(100% - 12.5px) 0%,
          100% 12.5px,
          calc(100% - 12px) 100%,
          0% 100%,
          0 12.5px,
          0% 0%
        );
      }
      .progressEnd {
        border-radius: 0 12px 12px 0;
        background-color: #4771ff;
        clip-path: polygon(100% 0, 100% 100%, 0% 100%, 12.5px 12.5px, 0% 0%);
      }

      .progressNoStart {
        color: #999999;
        background: #e7e7e7;
      }
    }
  }

  .infoDes {
    min-height: 86px;
    margin: 0 10px;
    box-sizing: border-box;
    padding: 20px 10px;
    background: rgba(71, 113, 255, 0.1);
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 10px));
    grid-template-rows: 1;
    gap: 10px;
    .infoLabel {
      font-size: 14px;
      color: #666666;
      line-height: 14px;
    }
    .infoContent {
      font-size: 14px;
      color: #333;
      line-height: 14px;
      margin-top: 10px;
      .cycleIcons {
        margin-right: 5px;
      }
    }
  }
  .infoDetailWrap {
    margin: 10px 15px;
    height: 338px;
    overflow: scroll;
    .labelWarp {
      :global {
        .ant-select-selection {
          border: 1px solid transparent;
          box-shadow: none;
          background: rgba(71, 113, 255, 0.1);
          color: #4771ff;
          border-radius: 0 15px 15px 0;
        }
      }
    }
    .detail_filter {
      width: auto;
      margin-left: 95px;
    }
    .cyclePoint {
      display: inline-flex;
      vertical-align: middle;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #4771ff;
      margin: 0 10px 0 22px;
      .subPoint {
        width: 7px;
        height: 7px;
        background: #4771ff;
        border-radius: 10px;
        border: 1px solid #4771ff;
      }
    }
    .cycleWarp {
      display: inline-flex;
      align-items: center;
    }
    .cycleStatusSuccess {
      color: rgb(28, 236, 22) !important;
      background: rgba(28, 236, 22, 0.1) !important;
    }
    .cycleStatus {
      margin-left: 10px;
      width: 48px;
      height: 18px;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      background: rgba(246, 0, 0, 0.1);
      border-radius: 149px;
      color: rgb(246, 0, 0);
    }
    .cycleIcons {
      display: inline-block;
      margin: 0 4px;
      position: relative;
      .success {
        background: #1cec16;
      }
      .error {
        background: rgb(246, 0, 0);
      }
      .cycleIconStatus {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -1px;
        right: -3px;
        height: 8px;
        width: 8px;
        border-radius: 4px;

        .icon {
          font-size: 6px;
          color: #fff;
        }
      }
    }
    .subTaskCycle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #333333;
      line-height: 24px;
      .icon {
        font-size: 14px;
      }
    }
    .cycleTab {
      display: flex;
      align-items: center;
      margin-left: 94px;
      font-size: 16px;
      color: #333333;
      line-height: 20px;
      .point {
        margin-right: 10px;
        display: inline-block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #4771ff;
      }
      .icon {
        font-size: 14px;
      }
    }
    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .labelWarp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
    }
    .removeBtn {
      font-size: 12px;
      color: #4771ff;
      cursor: pointer;
    }
    .detailLabel {
      font-size: 20px;
      color: #999999;
      line-height: 24px;
      margin: 0 10px 0 5px;
    }
    .detailLabelIcon {
      font-size: 22px;
      color: #4771ff;
    }
    .recordWarp {
      margin-top: 13px;
      margin-left: 85px;
      display: grid;
      grid-template-columns: repeat(4, 277px);
      grid-template-rows: repeat(auto-fill, 160px);
      gap: 16px;
      .iconWarp {
        position: relative;
      }
      .icon {
        position: absolute;
        right: -5px;
        top: -5px;
      }
      .fileImg {
        height: 160px;
        width: 277px;
        border-radius: 6px;
        cursor: pointer;
      }
      .thumbnail {
        height: 160px;
        width: 277px;
        border-radius: 6px;
        cursor: pointer;
      }
      .iconBox {
        cursor: pointer;
        height: 160px;
        width: 277px;
        cursor: pointer;
        border-radius: 6px;
        background: var(--body-bg);
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          font-size: 60px;
        }
      }
    }
  }
}
</style>
