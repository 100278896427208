import request from '../request';

const serviceName = '/iot-smart-helmet/inspection';

/**
 * @name 巡检任务列表
 */
export function getPage(data) {
  return request(`${serviceName}/page`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 删除巡检任务
 */
export function deleteInspection(id) {
  return request(`${serviceName}/${id}`, {
    method: 'DELETE',
  });
}
/**
 * @name 结束巡检任务
 */
export function cancelInspection(data) {
  return request(`${serviceName}/finish`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 巡检任务详情
 */
export function detail(data) {
  return request(`${serviceName}/detail`, {
    method: 'GET',
    body: data,
  });
}
