import request from '../request';

const serviceName = '/iot-smart-helmet/inspection';

/**
 * @name 创建任务
 */
export function addTask(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 修改任务
 */
export function putTask(data) {
  return request(`${serviceName}`, {
    method: 'PUT',
    body: data,
  });
}

/**
 * @name 查询巡检任务详情
 */
export function getTaskDetail(inspectionId) {
  return request(`${serviceName}/detail?inspectionId=${inspectionId}`, {
    method: 'GET',
  });
}

/**
 * @name 发布任务
 */
export function publishTask(inspectionId) {
  return request(`${serviceName}/publish?inspectionId=${inspectionId}`, {
    method: 'GET',
  });
}

/**
 * @name 获取周期详情
 */
export function getCycleDateById(data) {
  return request(`${serviceName}/getCycleDateById`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 获取明细设备列表
 */
export function getDeviceTaskList(data) {
  return request(`${serviceName}/deviceTaskList`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 获取巡检任务的全部设备和电子围栏
 */
export function getDeviceAndGeofence(data) {
  return request(`${serviceName}/deviceAndGeofence`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 获取子任务详情
 */
export function getSubTaskDetail(data) {
  return request(`${serviceName}/taskDetailById`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 获取设备明细记录
 */
export function getPageDeviceRecord(data) {
  return request(`${serviceName}/pageDeviceRecord`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 移除关联关系
 */
export function deleteBusiness(data) {
  return request(`${serviceName}/removeBusiness`, {
    method: 'DELETE',
    body: data,
  });
}
