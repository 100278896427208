export const CompareEnum = {
  /** @name 等于 */
  EQ: 'eq',
  /** @name 不等于 */
  NOTEQ: 'notEq',
  /** @name 等于任意一个 */
  EQONE: 'eqOne',
  /** @name 不等于任意一个 */
  UNEQONE: 'uneqOne',
  /** @name 包含 */
  CONTAINS: 'contains',
  /** @name 包含任意一个 */
  SOME: 'some',
  /** @name 不包含 */
  NOTCONTAINS: 'notContains',
  /** @name 同时包含 */
  ALSOINCLUDE: 'alsoInclude',
  /** @name 为空 */
  NULL: 'null',
  /** @name 不为空 */
  NOTNULL: 'notNull',
  /** @name 选择范围 */
  RANGE: 'range',
  /** @name 动态筛选 */
  FILTER: 'filter',
  /** @name 小于 */
  LT: 'lt',
  /** @name 小于等于 */
  LE: 'le',
  /** @name 大于 */
  GT: 'gt',
  /** @name 大于等于 */
  GE: 'ge',
  /** @name 手机已验证 */
  VALID: 'valid',
  /** @name 手机未验证 */
  NOTVALID: 'notValid',
  /** @name 属于 */
  BELONG: 'belong',
  /** @name 不属于 */
  NOTBELONG: 'notBelong',
};

export const CompareMap = {
  /** @name 等于 */
  eq: 'EQ',
  /** @name 不等于 */
  notEq: 'NOTEQ',
  /** @name 等于任意一个 */
  eqOne: 'EQ',
  /** @name 不等于任意一个 */
  uneqOne: 'NOTEQ',
  /** @name 包含 */
  contains: 'CONTAINS',
  /** @name 包含任意一个 */
  some: 'CONTAINS',
  /** @name 不包含 */
  notContains: 'NOTCONTAINS',
  /** @name 同时包含 */
  alsoInclude: 'CONTAINS',
  /** @name 为空 */
  null: 'NULL',
  /** @name 不为空 */
  notNull: 'NOTNULL',
  /** @name 选择范围 */
  range: 'RANGE',
  /** @name 动态筛选 */
  filter: 'FILTER',
  /** @name 小于 */
  lt: 'LT',
  /** @name 小于等于 */
  le: 'LE',
  /** @name 大于 */
  gt: 'GT',
  /** @name 大于等于 */
  ge: 'GE',
  /** @name 手机已验证 */
  valid: 'VALID',
  /** @name 手机未验证 */
  notValid: 'NOTVALID',
  /** @name 属于 */
  belong: 'EQ',
  /** @name 不属于 */
  notBelong: 'NOTEQ',
};

export const CompareDateNum = {
  /** @name 自定义 */
  Customize: 'CUSTOMIZE',
  /** @name 今天 */
  Today: 'TODAY',
  /** @name 昨天 */
  Yesterday: 'YESTERDAY',
  /** @name 明天 */
  Tomorrow: 'TOMORROW',
  /** @name 本周 */
  ThisWeek: 'THIS_WEEK',
  /** @name 上周 */
  LastWeek: 'LAST_WEEK',
  /** @name 下周 */
  NextWeek: 'NEXT_WEEK',
  /** @name 本月 */
  ThisMonth: 'THIS_MONTH',
  /** @name 上月 */
  FirstMonth: 'FIRST_MONTH',
  /** @name 下月 */
  NextMonth: 'NEXT_MONTH',
  /** @name 当前 */
  Current: 'CURRENT',
  /** @name 过去 */
  Past: 'PAST',
  /** @name 未来 */
  Future: 'FUTURE',
  /** @name 天 */
  Day: 'DAY',
  /** @name 周 */
  Week: 'WEEK',
  /** @name 月 */
  Month: 'MONTH',
};

/** @name 表单操作类型枚举 */
export const FormActionEnum = Object.freeze({
  // 流程操作
  /** @name 同意 */
  Agree: 1,
  /** @name 不同意 */
  Disagree: 2,
  /** @name 提交 */
  Submit: 3,
  /** @name 撤回审批 */
  Revoke: 4,
  /** @name 转交 */
  Redirect: 5,
  /** @name 暂存 */
  Temporary: 6,
  /** @name 提交并打印 */
  SubmitAndPrint: 7,
  /** @name 回退 */
  GoBack: 8,
  /** @name 结束流程 */
  EndProcess: 9,
  /** @name 重新提交 */
  ReSubmit: 10,
  /** @name 激活流程 */
  Activate: 11,
  /** @name 发起人撤销流程 */
  RevokeProcess: 12,
  /** @name 调整负责人 */
  ChangeOwner: 13,
  /** @name 重新提交（回退至开始节点） */
  GoBackResubmit: 14,
  /** @name 稍后处理 */
  DealWithLater: 15,
  /** @name 取消稍后处理 */
  CancelDealWithLater: 16,
  /** @name 发起流程 */
  InitiateProcess: 17,

  // 基本操作
  /** @name 查看 */
  Get: 100,
  /** @name 添加 */
  Add: 101,

  // 表单操作
  /** @name 复制 */
  Copy: 102,
  /** @name 编辑 */
  Edit: 103,
  /** @name 保存 */
  Save: 'Save',
  /** @name 取消保存 */
  CancelSave: 'CancelSave',
  /** @name 删除 */
  Delete: 104,
  /** @name 批量打印 */
  BatchPrint: 105,
  /** @name 批量编辑 */
  BatchEdit: 106,
  /** @name 导入 */
  Import: 107,
  /** @name 导出 */
  Export: 108,
  /** @name 分享 */
  Share: 109,
  /** @name 收藏 */
  Favorite: 110,
  /** @name 取消收藏 */
  Unfavorite: 'Unfavorite',
  /** @name 打印 */
  Print: 111,
  /** @name 更新数据（数据流） */
  UpdateData: 112,
  /** @name 动态参数（数据流） */
  DynamicParameter: 113,
});

/** @name 表单权限类型枚举 */
export const FormPermissionEnum = {
  /** @name 管理全部数据权限 */
  ManageData: '-1',
  /** @name 查看数据权限 */
  ViewData: '-2',
  /** @name 提交数据权限 */
  AddData: '-3',
  /** @name 我的数据 */
  MyData: '-4',
  /** @name 全部有权限的数据 */
  PermissionData: '-5',
  /** @name 查看模式 */
  ViewMode: '-6',
  /** @name 表单外链填写 */
  PublicLinkWrite: '-7',
  /** @name 表单外链编辑 */
  PublicLinkEdit: '-8',
  /** @name 表单外链列表查询 */
  PublicLinkQuery: '-9',
};

/**
 * @name 表单类型
 * @deprecated 用FlowType
 */
export const FormTypeEnum = {
  /** @name 普通表单 */
  NO_ENABLE_PROCESS: 1,
  /** @name 自由流程表单 */
  ENABLE_FREE_PROCESS: 2,
  /** @name 固定流程表单 */
  ENABLE_FIXED_PROCESS: 3,
  /** @name 工作流程表单 */
  ENABLE_WORK_PROCESS: 4,
};

/** @name 表单授权类型枚举 */
export const FormAuthEnum = {
  /** @name 查看 */
  View: 'GET',
  /** @name 编辑 */
  Edit: 'UPDATE',
  /** @name 删除 */
  Delete: 'DELETE',
  /** @name 推送云端 */
  Push: 'PUSH',
  /** @name 开发管理员 */
  Develop: 'DEVELOP',
  /** @name 数据管理员 */
  Data: 'DATA',
  /** @name 数据引用管理员 */
  Reference: 'REFERENCE',
};

/** @name 表单多列枚举 */
export const ColumnEnum = {
  /** @name 1列 */
  'Column-1': 'column-1',
  /** @name 3/4列 */
  'Column-3-4': 'column-3-4',
  /** @name 2/3列 */
  'Column-2-3': 'column-2-3',
  /** @name 1/2列 */
  'Column-2': 'column-2',
  /** @name 1/3列 */
  'Column-3': 'column-3',
  /** @name 1/4列 */
  'Column-4': 'column-4',
};

/** @name 应用子集种类 */
export const FormCategoryType = {
  /** @name 一级分组 */
  LEVEL_FIRST_GROUP: 1,
  /** @name 二级分组 */
  LEVEL_SECOND_GROUP: 2,
  /** @name 普通表单 */
  FORM: 3,
  /** @name 流程表单 */
  PROCESS_FORM: 4,
  /** @name 数据处理 */
  DATA_PROCESS: 5,
  /** @name 仪表盘 */
  DASHBOARD: 6,
  /** @name 链接页面 */
  LINK: 7,
  /** @name 聚合表 */
  AGGREGATE_TABLE: 8,
};

/** @name 排除分组后的应用子集类型列表 */
export const NormalFormCategoryTypes = Object.values(FormCategoryType).filter(
  type =>
    type !== FormCategoryType.LEVEL_FIRST_GROUP &&
    type !== FormCategoryType.LEVEL_SECOND_GROUP,
);

/** @name 分组种类集合 */
export const GroupCategoryArray = [
  FormCategoryType.LEVEL_FIRST_GROUP,
  FormCategoryType.LEVEL_SECOND_GROUP,
];

export const FormCategoryTypeName = {
  /** @name 一级分组 */
  [FormCategoryType.LEVEL_FIRST_GROUP]: 'LEVEL_FIRST_GROUP',
  /** @name 二级分组 */
  [FormCategoryType.LEVEL_SECOND_GROUP]: 'LEVEL_SECOND_GROUP',
  /** @name 普通表单 */
  [FormCategoryType.FORM]: 'FORM',
  /** @name 流程表单 */
  [FormCategoryType.PROCESS_FORM]: 'PROCESS_FORM',
  /** @name 数据处理 */
  [FormCategoryType.DATA_PROCESS]: 'DATA_PROCESS',
  /** @name 仪表盘 */
  [FormCategoryType.DASHBOARD]: 'DASH_BOARD',
  /** @name 链接 */
  [FormCategoryType.LINK]: 'LINK',
  /** @name 聚合表(实时表) */
  [FormCategoryType.AGGREGATE_TABLE]: 'AGGREGATE_TABLE',
};

/** @name 表单处理状态 */
export const FormProcessingStateEnum = {
  /** @name 处理中 */
  PROCESSING: 'PROCESSING',
  /** @name 已完成 */
  FINISHED: 'FINISHED',
};

/** @name 表单来源类型 */
export const FormSourceTypeEnum = {
  /** @name 普通 */
  COMMON: 'COMMON',
  /** @name 业务规则 */
  BUSINESS_RULE: 'BUSINESS_RULE',
  /** @name 表单关联 */
  FORM_RELATION: 'FORM_RELATION',
  /** @name 数据查询 */
  RELATION_QUERY: 'RELATION_QUERY',
  /** @name 自定义按钮 */
  CUSTOM_BUTTON: 'CUSTOM_BUTTON',
  /** @name 流程 */
  FLOW: 'FLOW',
  /** @name 子流程发起 */
  SUB_FLOW: 'SUB_FLOW',
  /** @name 数据更新 */
  INTELLIGENCE: 'INTELLIGENCE',
  /** @name 外链 */
  LINK_FORM: 'LINK_FORM',
  /** @name 导入 */
  IMPORT: 'IMPORT',
  /** @name 批量修改 */
  BATCH_UPDATE: 'BATCH_UPDATE',
  /** @name 按钮组件 */
  BUTTON: 'BUTTON',
};

/** @name 表单统计数据来源 */
export const FormStatisticsSourceEnum = {
  /** @name 正常录入 */
  COMMON: 'COMMON',
  /** @name 外链 */
  FORM_LINK: 'FORM_LINK',
  /** @name 业务新增 */
  BUSINESS_ADD: 'BUSINESS_ADD',
  /** @name 导入 */
  IMPORT: 'IMPORT',
};
