<template>
  <div :class="$style.taskWrap">
    <div :class="$style.steps">
      <a-steps :current="step" label-placement="vertical">
        <a-step title="基础信息" />
        <a-step title="任务详情" />
        <a-step title="任务设置" />
      </a-steps>
    </div>
    <div :class="$style.form">
      <BaseInfo v-show="step === 0" ref="baseInfo" :formData="formData" />
      <TaskDetail
        v-show="step === 1"
        ref="taskDetail"
        :cycleType="cycleType"
        :formData="formData"
      />
      <TaskSetting v-show="step === 2" ref="taskSetting" :formData="formData" />
    </div>
    <div :class="$style.footer">
      <async-button
        type="default"
        size="small"
        v-if="step === 0"
        :click="handleCancel"
      >
        取消
      </async-button>
      <async-button type="primary" size="small" :click="lastStep" v-else>
        上一步
      </async-button>
      <template v-if="step === 2 && operationType !== 'detail'">
        <async-button type="default" size="small" :click="handleSave">
          保存
        </async-button>
        <async-button type="primary" size="small" :click="handlePublish">
          发布
        </async-button>
      </template>
      <async-button
        type="primary"
        size="small"
        :click="nextStep"
        v-if="step !== 2"
      >
        下一步
      </async-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import AsyncButton from '@/components/async-button';
import BaseInfo from './base-info.vue';
import TaskDetail from './task-detail.vue';
import TaskSetting from './task-setting.vue';
import { createFormModal } from '@triascloud/x-components';
import {
  addTask,
  publishTask,
  getTaskDetail,
  putTask,
} from '@/services/smart-hat/task.js';

@Component({
  components: {
    AsyncButton,
    BaseInfo,
    TaskDetail,
    TaskSetting,
  },
})
export default class AddTask extends Vue {
  @Prop({ type: String, default: 'edit' }) operationType;
  @Prop({ type: String, default: '' }) taskId;

  created() {
    this.getFormData();
  }

  formData = null;
  async getFormData() {
    if (this.operationType !== 'add') {
      this.formData = await getTaskDetail(this.taskId);
    } else {
      this.formData = null;
    }
  }

  get tasks() {
    const tasks = this.$refs.taskDetail.taskList.map(item => {
      return {
        endDate: item.endDate,
        endTime: item.endTime,
        formIdList: item.formIdList,
        idxGeofenceId: item.idxGeofenceId,
        mobileUpdate: item.mobileUpdate,
        operationIdList: item.operationIdList,
        operationType: item.operationType,
        photoNumber: item.photoNumber,
        positionTypeList: item.positionTypeList,
        startDate: item.startDate,
        startTime: item.startTime,
        tagIdList: item.tagIdList,
        taskDesc: item.taskDesc,
        taskId: this.operationType === 'edit' ? item.taskId : undefined,
        taskItemReminderContent: this.replacePostContent(
          item.taskItemReminderContent,
        ),
        taskName: item.taskName,
        videoNumber: item.videoNumber,
      };
    });
    return tasks;
  }

  get form() {
    const form = {
      ...this.$refs.baseInfo.form,
      ...this.$refs.taskSetting.form,
    };
    form.newTaskReminderContent = this.replacePostContent(
      form.newTaskReminderContent,
    );
    form.taskItemAddressReminderContent = this.replacePostContent(
      form.taskItemAddressReminderContent,
    );
    form.taskItemCompleteReminderContent = this.replacePostContent(
      form.taskItemCompleteReminderContent,
    );
    form.tasks = this.tasks;
    return form;
  }

  step = 0;
  cycleType = 'DESIGNATED'; // 任务周期
  nextStep() {
    if (this.step === 2) return;
    this.step++;
    this.cycleType = this.form.cycleType;
  }

  lastStep() {
    if (this.step === 0) return;
    this.step--;
  }

  async handleSave() {
    try {
      if (this.operationType === 'edit') {
        await putTask({ ...this.form, inspectionId: this.taskId });
      } else {
        await addTask(this.form);
      }
      await this.$message.success({
        content: '操作成功！',
        duration: 0.5,
      });
      this.$emit('submit');
    } catch {
      return false;
    }
  }

  async handleCancel() {
    this.$emit('cancel');
  }

  async handlePublish() {
    await createFormModal(
      () => (
        <div class={this.$style.modalContent}>
          <div>确认发布任务？发布后无法再次编辑任务！</div>
          <div class={this.$style.tipText}>
            注意：一旦发布将会按设置通知设备，且无法取消！
          </div>
        </div>
      ),
      {
        width: 450,
        title: '操作确认',
        onOk: async () => {
          if (this.operationType === 'edit') {
            await publishTask(this.taskId);
          } else {
            const data = await addTask(this.form);
            await publishTask(data);
          }
          await this.$message.success({
            content: '操作成功！',
            duration: 0.5,
          });
          this.$emit('submit');
        },
      },
    );
  }

  replacePostContent(content) {
    return content
      .replace(/{任务项名称}/g, 'TASK_NAME')
      .replace(/{任务项时段}/g, 'TASK_TIME')
      .replace(/{任务项个数}/g, 'TASK_NUM')
      .replace(/{任务项说明}/g, 'TASK_DESC')
      .replace(/{任务进度}/g, 'INSPECTION_SCHEDULE')
      .replace(/{任务时段}/g, 'INSPECTION_TIME')
      .replace(/{任务名称}/g, 'INSPECTION_NAME')
      .replace(/{地点}/g, 'LOCATION_NAME');
  }
}
</script>

<style lang="less" module>
.taskWrap {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  .steps {
    width: 550px;
  }
  .form {
    max-height: 650px;
    width: 100%;
    padding-right: 4px;
    margin-top: 6px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .footer {
    display: flex;
    gap: 30px;
    padding-top: 10px;
    align-self: flex-end;
    margin-top: auto;
  }
}
.modalContent {
  text-align: center;
  .tipText {
    margin-top: 15px;
    color: rgb(252, 54, 54);
  }
}
</style>
