import request from '../request';

const serviceName = '/custom-application/application';

/** @name 应用列表搜索类型可用值 */
export const ApplicationSearchEnum = {
  /** @name 所有应用分组表单 */
  FOUND_ALL: 'FOUND_ALL',
  /** @name 所有应用分组 */
  FOUND_APP_GROUP: 'FOUND_APP_GROUP',
  /** @name 所有应用表单 */
  FOUND_APP_FORM: 'FOUND_APP_FORM',
  /** @name 所有应用普通表单 */
  FOUND_APP_COMMON_FORM: 'FOUND_APP_COMMON_FORM',
  /** @name 所有应用流程表单 */
  FOUND_APP_PROCESS_FORM: 'FOUND_APP_PROCESS_FORM',
  /** @name 当前应用所有表单 */
  CURRENT_APP_ALL: 'CURRENT_APP_ALL',
  /** @name 当前应用所有普通表单 */
  CURRENT_APP_COMMON_FORM: 'CURRENT_APP_COMMON_FORM',
  /** @name 当前应用所有流程表单 */
  CURRENT_APP_PROCESS_FORM: 'CURRENT_APP_PROCESS_FORM',
  /** @name 所有应用下的分组-流程表单表单（包含分组） */
  FOUND_ALL_PROCESS: 'FOUND_ALL_PROCESS',
  /** @name 所有应用下的分组-普通表单（包含分组） */
  FOUND_ALL_COMMON: 'FOUND_ALL_COMMON',
  /** @name 所有启动流程表单 */
  FOUND_START_PROCESS_FORM: 'FOUND_START_PROCESS_FORM',
  /** @name 过滤没有查看权限的表单 */
  FOUND_VIEW_DATA_FORM: 'FOUND_VIEW_DATA_FORM',
  /** @name 过滤没有提交权限的表单 */
  FOUND_SUBMIT_DATA_FORM: 'FOUND_SUBMIT_DATA_FORM',
  /** @name 所有应用数据处理 */
  FOUND_APP_DATA_PROCESS: 'FOUND_APP_DATA_PROCESS',
  /** @name 允许设置业务规则的表单 */
  FOUND_BUSINESS_RULE_FORM: 'FOUND_BUSINESS_RULE_FORM',
  /** @name 所有应用聚合表（实时表） */
  FOUND_APP_REAL_TIME_TABLE: 'FOUND_APP_REAL_TIME_TABLE',
  /** @name 所有应用仪表盘 */
  FOUND_APP_CHART: 'FOUND_APP_CHART',
  /** @name 获取有查看权限表单（包括权限组、应用表单开发-数据权限） */
  FOUND_DATA_INPUT_NODE_FORM: 'FOUND_DATA_INPUT_NODE_FORM',
};

/**
 * @name 获取全部应用表单
 */
export function getAllForm() {
  return request(`${serviceName}/list/FOUND_APP_FORM`, {});
}

/**
 * @name 查询可引用的数据源列表
 * @param { string } searchType
 * @param { { flowTypeCode: number, withLock: boolean, needDeal: boolean } } params
 */
export function getReferenceDataSourceList(
  searchType = ApplicationSearchEnum.FOUND_ALL,
  params,
) {
  return request(`${serviceName}/reference/${searchType}`, {
    method: 'GET',
    body: {
      needDeal: true,
      withLock: true,
      ...params,
    },
  });
}
