<template>
  <div :class="$style.taskDetail">
    <div style="margin-bottom: 4px; color: var(--font-info)">任务项</div>
    <transition-group name="slide">
      <div
        :class="$style.taskItem"
        v-for="taskItem in taskList"
        :key="taskItem.id"
      >
        <div :class="$style.header">
          <div :class="$style.title">
            <a-icon
              type="caret-down"
              :class="[$style.icon]"
              :style="taskItem.isHide ? { transform: 'rotate(-90deg)' } : ''"
              @click="taskItem.isHide = !taskItem.isHide"
            />
            {{ taskItem.taskName }}
          </div>
          <a-icon
            type="delete"
            :class="$style.icon"
            @click="deleteTask(taskItem)"
          />
        </div>
        <div :class="[$style.taskForm, taskItem.isHide ? $style.hide : '']">
          <a-form-model :rules="rules" style="padding: 20px" :model="taskItem">
            <a-row :gutter="16" :class="$style.formRow">
              <a-col :span="12">
                <a-form-model-item label="名称" prop="taskName">
                  <a-input
                    v-model="taskItem.taskName"
                    placeholder="任务名称，限32字符"
                    :maxLength="32"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="说明" prop="taskDesc">
                  <a-input
                    v-model="taskItem.taskDesc"
                    placeholder="任务说明，限150字符"
                    :maxLength="150"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="16" :class="$style.formRow">
              <a-col :span="12">
                <a-form-model-item label="时段">
                  <a-range-picker
                    format="MM-DD HH:mm"
                    :show-time="{ format: 'YYYY-MM-DD HH:mm' }"
                    v-model="taskItem.dateRange"
                    :allowClear="false"
                    :placeholder="[
                      $t('hat.clockRecord.startDate'),
                      $t('hat.clockRecord.endDate'),
                    ]"
                    style="width: 100%"
                    :disabled-date="disabledDate"
                    @change="handleSearchTime($event, taskItem)"
                    @calendarChange="calendarPriceRangeChange"
                    v-if="cycleType === 'DESIGNATED'"
                  />
                  <template v-if="cycleType !== 'DESIGNATED'">
                    <a-time-picker
                      placeholder="开始时间"
                      format="HH:mm"
                      v-model="taskItem.startTime"
                      value-format="HH:mm"
                    /><span style="margin: 0 8px">-</span
                    ><a-time-picker
                      placeholder="结束时间"
                      format="HH:mm"
                      value-format="HH:mm"
                      v-model="taskItem.endTime"
                    />
                  </template>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item prop="idxGeofenceId">
                  <span slot="label"
                    >地点<a-tooltip
                      title="电子围栏的活动区域，禁止区域均作为有效区域！"
                      :class="$style.tipIcon"
                    >
                      <a-icon type="info-circle" /> </a-tooltip
                  ></span>
                  <fence-select
                    v-model="taskItem.idxGeofenceId"
                    style="width: 200px"
                  />
                  <a
                    style="margin-left: 10px"
                    v-if="taskItem.idxGeofenceId"
                    @click="openMap(taskItem)"
                    >预览</a
                  >
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="16" :class="$style.formRow">
              <a-col :span="12">
                <a-form-model-item prop="ask">
                  <span slot="label"
                    >要求<a-tooltip :class="$style.tipIcon">
                      <a-icon type="info-circle" />
                      <div slot="title">
                        <p>
                          1、若执行设备包含B款（不支持拍照/录像），建议开启手机上传；
                        </p>
                        <p>2、确保任务执行成员所选表单的提交权限；</p>
                      </div>
                    </a-tooltip></span
                  >
                  <a-row>
                    <a-col :span="12">
                      <a-checkbox
                        v-model="taskItem.photo"
                        @change="
                          handleCheckChange($event, taskItem, 'photoNumber')
                        "
                      >
                        拍照
                      </a-checkbox>
                      <template v-if="taskItem.photo">
                        <a-select
                          default-value="1"
                          v-model="taskItem.photoNumber"
                          style="width: 70px; margin-right: 8px"
                        >
                          <a-select-option
                            v-for="(item, index) in 10"
                            :key="index"
                            :value="index + 1"
                          >
                            {{ index + 1 }}
                          </a-select-option>
                          <a-select-option value="11"> 10+ </a-select-option>
                        </a-select>
                        <span>张</span>
                      </template>
                    </a-col>
                    <a-col :span="12">
                      <a-checkbox
                        v-model="taskItem.video"
                        @change="
                          handleCheckChange($event, taskItem, 'videoNumber')
                        "
                      >
                        录像
                      </a-checkbox>
                      <template v-if="taskItem.video">
                        <a-select
                          default-value="1"
                          style="width: 70px; margin-right: 8px"
                          v-model="taskItem.videoNumber"
                        >
                          <a-select-option
                            v-for="(item, index) in 10"
                            :key="index"
                            :value="index + 1"
                          >
                            {{ index + 1 }}
                          </a-select-option>
                          <a-select-option value="11"> 10+ </a-select-option>
                        </a-select>
                        <span>个</span>
                      </template>
                    </a-col>
                  </a-row>
                  <a-row style="margin-top: 4px">
                    <a-col :span="6">
                      <a-checkbox v-model="taskItem.form">
                        提交表单
                      </a-checkbox>
                    </a-col>
                    <a-col :span="18">
                      <form-tree-select
                        v-if="taskItem.form"
                        v-model="taskItem.formIdList"
                        :filter="formFilter"
                        :multiple="true"
                        placeholder="请选择表单"
                      />
                    </a-col>
                  </a-row>
                  <a-row v-if="taskItem.photo || taskItem.video">
                    <a-checkbox v-model="taskItem.mobileUpdate"
                      ><span>
                        手机本地上传照片/视频（限任务项有效期内）</span
                      ></a-checkbox
                    >
                  </a-row>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item prop="positionTypeList">
                  <span slot="label"
                    >开始方式<a-tooltip :class="$style.tipIcon">
                      <dl slot="title">
                        <dt>任务项触发方式:</dt>
                        <dd>
                          1、安全帽定位：必选，在任务项时间内，安全帽的定位到达设置地，点范围内即判定为开始任务(建议到达指定位置后长按更新设备定位)；
                        </dd>
                        <dd>
                          2、手机扫码定位：可选，用户扫描任务项的二维码后授权获取手机定位，若扫码成功且手机定位在设置地点100米范围内则判定任务开始；
                        </dd>
                        <dd>
                          3、注意：勾选两者后，需要2者定位同时符合要求方可开始任务！
                        </dd>
                      </dl>
                      <a-icon type="info-circle" /> </a-tooltip
                  ></span>
                  <a-checkbox-group v-model="taskItem.positionTypeList">
                    <a-checkbox value="DEVICE"> 安全帽定位 </a-checkbox>
                    <a-checkbox value="MOBILE"> 手机扫码定位 </a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row
              :gutter="16"
              :class="$style.formRow"
              style="margin-top: 20px"
            >
              <a-col :span="12">
                <a-form-model-item
                  label="任务项提醒内容"
                  prop="taskItemReminderContent"
                >
                  <a-textarea
                    :rows="7"
                    v-model="taskItem.taskItemReminderContent"
                    :id="taskItem.id"
                  />
                  <div :class="$style.tags">
                    <span @click="insertVariable('{任务项名称}', taskItem)"
                      >任务项名称</span
                    >
                    <span @click="insertVariable('{任务项说明}', taskItem)"
                      >任务项说明</span
                    >
                    <span @click="insertVariable('{任务项时段}', taskItem)"
                      >任务项时段</span
                    >
                    <span @click="insertVariable('{地点}', taskItem)"
                      >地点</span
                    >
                  </div>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="执行" prop="operationType">
                  <a-radio-group
                    default-value="DEVICE"
                    v-model="taskItem.operationType"
                    @change="taskItem.operationIdList = []"
                  >
                    <a-radio value="DEVICE">
                      {{ $t('hat.electronFence.byDevice') }}
                    </a-radio>
                    <a-radio value="GROUP">
                      {{ $t('hat.electronFence.byGroup') }}
                    </a-radio>
                    <a-radio value="PROJECT">
                      {{ $t('hat.deviceManagement.form.project') }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item
                  prop="device"
                  v-if="taskItem.operationType === 'DEVICE'"
                >
                  <a-select
                    mode="multiple"
                    v-model="taskItem.operationIdList"
                    show-search
                    option-filter-prop="children"
                    :filter-option="true"
                    :class="$style.selectList"
                  >
                    <a-select-option v-for="item in deviceList" :key="item.id">
                      {{ item.value }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  prop="group"
                  v-else-if="taskItem.operationType === 'GROUP'"
                >
                  <a-select
                    mode="multiple"
                    show-search
                    option-filter-prop="children"
                    :filter-option="true"
                    v-model="taskItem.operationIdList"
                    :class="$style.selectList"
                  >
                    <a-select-option v-for="item in groupList" :key="item.id">
                      {{ item.value }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  :label="$t('hat.deviceManagement.form.belongingProject')"
                  prop="project"
                  v-else
                >
                  <project-select
                    v-model="taskItem.operationIdList"
                    style="margin-bottom: 8px"
                  />
                </a-form-model-item>
                <!-- <span :class="$style.tipText">共包含1台设备</span> -->
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-model-item prop="tagList">
                  <span slot="label"
                    >自动标签<a-tooltip :class="$style.tipIcon">
                      <div slot="title">
                        <p>
                          将为符合任务时间/地点要求的、由安全幅拍摄的照片/视频自动添加此处设定的标签
                        </p>
                        <p>备注:若照片/视频已有相同标签则不重复添加!</p>
                      </div>
                      <a-icon type="info-circle" /> </a-tooltip
                  ></span>
                  <a-tag
                    v-for="(item, index) in taskItem.tagList"
                    :key="item.id"
                    closable
                    @close="deleteTag(index, taskItem)"
                    :color="item.color || '#5977d5'"
                    >{{ item.name }}</a-tag
                  >
                  <div
                    style="display: inline-block"
                    v-if="taskItem.isShowTagSelect"
                  >
                    <a-select
                      style="width: 134px"
                      v-model="form.groupId"
                      @change="changeGroup"
                    >
                      <a-select-option
                        :key="item.pkId"
                        :value="item.pkId"
                        v-for="item in panes"
                      >
                        {{ item.tagName }}
                      </a-select-option>
                    </a-select>
                    <a-select style="width: 134px" v-model="form.subTagId">
                      <a-select-option
                        :key="item.pkId"
                        :value="item.pkId"
                        v-for="item in subList"
                      >
                        {{ item.tagName }}
                      </a-select-option>
                    </a-select>
                    <a
                      style="color: var(--font-info); margin: 0 8px"
                      @click="taskItem.isShowTagSelect = false"
                      >取消</a
                    >
                    <a @click="onTagSubmit(taskItem)">确定</a>
                  </div>
                  <a
                    :class="$style.addTag"
                    @click="addTag(taskItem)"
                    v-if="!taskItem.isShowTagSelect"
                    ><a-icon type="plus" />添加</a
                  >
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </transition-group>
    <div :class="$style.addTask" @click="addTask">
      <a-icon type="plus" />添加任务项
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  getDeviceList,
  getGroupList,
} from '@/services/smart-hat/device-management';
import ProjectSelect from '@/views/hat/components/project-select/project-select.vue';
import { uuid } from '@triascloud/utils';
import { getTagGroup } from '@/services/smart-hat/tag-group';
import FenceSelect from '@/views/hat/components/fence-select/fence-select.vue';
import { createModal } from '@triascloud/x-components';
import FenceMap from './fence-map.vue';
import { FormTreeSelect } from '@/components/form-tree-select';

const DAY = 24 * 60 * 60 * 1000;
@Component({
  components: {
    ProjectSelect,
    FenceSelect,
    FormTreeSelect,
  },
})
export default class TaskDetail extends Vue {
  @Prop({ type: String, default: 'DESIGNATED' }) cycleType;
  @Prop({ type: Object, default: null }) formData;

  mounted() {
    this.getSelectList();
    this.taskList = [{ ...this.taskForm, id: uuid() }];
    this.getTagData();
  }

  @Watch('formData')
  initFormData(newValue) {
    if (newValue) {
      this.taskList = this.formData.tasks.map(item => {
        if (this.cycleType === 'DESIGNATED') {
          if (
            item.startDate &&
            item.startTime &&
            item.endDate &&
            item.endTime
          ) {
            item.dateRange = [
              item.startDate + ' ' + item.startTime,
              item.endDate + ' ' + item.endTime,
            ];
          }
        }
        item.id = uuid();
        item.photo = item.photoNumber !== 0;
        item.video = item.videoNumber !== 0;
        item.form = item.formIdList.length > 0;
        item.isHide = false;
        item.isShowTagSelect = false;
        item.taskItemReminderContent = this.replaceContent(
          item.taskItemReminderContent,
        );
        item.tagList = item.tags.map(tagItem => ({
          id: tagItem.tagId,
          name: tagItem.tagName,
          color: tagItem.tagColor,
        }));
        return { ...item };
      });
    }
  }

  taskForm = {
    taskName: '',
    taskDesc: '',
    idxGeofenceId: '',
    positionTypeList: [],
    formIdList: [],
    tagIdList: [],
    mobileUpdate: false,
    videoNumber: 0,
    photoNumber: 0,
    taskItemReminderContent: '',
    operationType: 'DEVICE',
    operationIdList: [],
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    dateRange: [],
    photo: false,
    video: false,
    form: false,
    isHide: false,
    isShowTagSelect: false,
    tagList: [],
  };
  form = {
    subTagId: '',
    groupId: '',
  };
  rules = {
    taskName: [
      {
        required: true,
        message: '',
      },
    ],
    idxGeofenceId: [
      {
        required: true,
        message: '',
      },
    ],
    ask: [
      {
        required: true,
        message: '',
      },
    ],
    positionTypeList: [
      {
        required: true,
        message: '',
      },
    ],
    operationType: [
      {
        required: true,
        message: '',
      },
    ],
  };

  taskList = [];
  addTask() {
    if (this.taskList.length === 10) {
      this.$message.warn('最多允许添加10个子任务项!');
      return;
    }
    this.taskForm.tagList = [];
    this.taskForm.tagIdList = [];
    this.taskList.push({ ...this.taskForm, id: uuid() });
  }
  deleteTask(taskItem) {
    const idx = this.taskList.findIndex(e => e.id === taskItem.id);
    this.taskList.splice(idx, 1);
  }

  async getSelectList() {
    try {
      await Promise.all([this.getDevices(), this.getGroups()]);
    } catch {
      return false;
    }
  }
  deviceList = [];
  async getDevices() {
    const data = {
      current: 1,
      size: 500,
    };
    this.deviceList = (await getDeviceList(data)).records.map(item => ({
      id: item.deviceId,
      value: item.userId
        ? `${item.deviceName}(${item.userName})`
        : item.deviceName,
    }));
  }
  groupList = [];
  async getGroups() {
    const data = {
      current: 1,
      size: 500,
    };
    this.groupList = (await getGroupList(data)).records.map(item => ({
      id: item.pkId,
      value: item.deviceGroupName,
    }));
  }

  // 向输入框光标位置插入文本
  async insertVariable(value, taskItem) {
    const myField = document.getElementById(taskItem.id);
    if (myField.selectionStart || myField.selectionStart === 0) {
      let startPos = myField.selectionStart;
      let endPos = myField.selectionEnd;
      taskItem.taskItemReminderContent =
        myField.value.substring(0, startPos) +
        value +
        myField.value.substring(endPos, myField.value.length);
      await this.$nextTick();
      myField.focus();
      myField.setSelectionRange(endPos + value.length, endPos + value.length);
    } else {
      taskItem.taskItemReminderContent = value;
    }
  }

  replaceContent(content) {
    return content
      .replace(/TASK_NAME/g, '{任务项名称}')
      .replace(/TASK_TIME/g, '{任务项时段}')
      .replace(/TASK_NUM/g, '{任务项个数}')
      .replace(/TASK_DESC/g, '{任务项说明}')
      .replace(/INSPECTION_SCHEDULE/g, '{任务进度}')
      .replace(/INSPECTION_TIME/g, '{任务时段}')
      .replace(/INSPECTION_NAME/g, '{任务名称}')
      .replace(/LOCATION_NAME/g, '{地点}');
  }

  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  disabledDate(current) {
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = 1 * DAY;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays ||
      current < this.$moment().startOf('day')
    );
  }
  handleSearchTime(time, taskItem) {
    taskItem.startDate = this.$moment(time[0]).format('YYYY-MM-DD');
    taskItem.endDate = this.$moment(time[1]).format('YYYY-MM-DD');
    taskItem.startTime = this.$moment(time[0]).format('HH:mm');
    taskItem.endTime = this.$moment(time[1]).format('HH:mm');
  }

  /** 文件标签 */
  changeGroup() {
    this.form.subTagId = '';
  }
  get subList() {
    if (this.form.groupId) {
      const item = this.panes.find(v => v.pkId === this.form.groupId);
      return item.tags;
    }
    return [];
  }
  panes = [];
  totalSubTagList = [];
  async getTagData() {
    const data = await getTagGroup();
    this.panes = data;
    data.forEach(item => {
      this.totalSubTagList.push(...item.tags);
    });
  }
  deleteTag(index, taskItem) {
    taskItem.tagIdList.splice(index, 1);
    taskItem.tagList.splice(index, 1);
  }
  onTagSubmit(taskItem) {
    const idx = this.subList.findIndex(e => e.pkId === this.form.subTagId);
    if (taskItem.tagList.findIndex(e => e.id === this.form.subTagId) > -1) {
      this.$message.warning('标签已存在');
      return;
    }
    if (taskItem.tagList.length === 10) {
      this.$message.warning('最多允许添加10个标签！');
      return;
    }
    taskItem.tagList.push({
      id: this.subList[idx].pkId,
      name: this.subList[idx].tagName,
      color: this.subList[idx].tagColor,
    });
    taskItem.tagIdList.push(this.form.subTagId);
    taskItem.isShowTagSelect = false;
  }
  addTag(taskItem) {
    this.form.subTagId = '';
    this.form.groupId = '';
    taskItem.isShowTagSelect = true;
  }

  openMap(taskItem) {
    createModal(() => <FenceMap fenceId={taskItem.idxGeofenceId} />, {
      width: 860,
      title: '地图选点',
      maskClosable: false,
    });
  }

  formFilter(item) {
    if (item.category === 8 || item.category === 5) return false;
    return true;
  }

  handleCheckChange(e, item, name) {
    const { checked } = e.target;
    item[name] = checked ? 1 : 0;
  }
}
</script>

<style lang="less" module>
.taskDetail {
  height: 650px;
  .taskItem {
    width: 802px;
    margin-bottom: 5px;
    .header {
      width: 100%;
      height: 32px;
      padding: 0 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--primary-fade-20);
      .icon {
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s;
      }
    }
    .taskForm {
      max-height: 1000px;
      overflow: hidden;
      border: 1px solid var(--border);
      transition: max-height 0.3s;
    }
    .hide {
      max-height: 0;
      border: 1px solid transparent;
    }
  }
  .addTask {
    margin-top: 5px;
    border-radius: 4px;
    color: var(--primary);
    cursor: pointer;
  }
}
.formRow {
  margin-bottom: 8px;
}
.selectList {
  :global {
    .ant-select-selection--multiple {
      height: 140px;
      overflow-y: auto;
    }
  }
}
.tags {
  span {
    cursor: pointer;
    font-size: 12px;
    background-color: var(--primary-fade-20);
    padding: 3px 6px;
    border-radius: 6px;
    margin-right: 10px;
  }
}
.tipIcon {
  margin: 0 6px;
  color: var(--font-info);
}
.addTag {
  margin-left: 10px;
}
</style>
<style lang="less" scoped>
.slide-enter-active {
  animation: slide-in 0.1s ease-out;
}
.slide-leave-active {
  animation: slide-out 0.1s ease-out;
  /* 这里在离开的时候，需要让这个元素脱离标准流，不然后面的元素动不了 */
  position: absolute;
}
/* 想要让动画平滑一点，需要加v-move的类名，v-可以别name的值 */
.slide-move {
  transition: all 0.1s;
}
@keyframes slide-in {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(50px);
  }
}
</style>
