<template>
  <x-tree-select
    :overlayClassName="$style['x-tree-select']"
    :value="value"
    :data="treeData"
    :checkboxLabel="showCurrent ? $t('controls.fieldList.currentApp') : ''"
    :filterMethod="showCurrent ? handleFilterMethod : null"
    v-bind="$attrs"
    @input="handleInput"
    ref="treeSelect"
    :not-found-label="notFoundLabel"
    :class="$style.select"
    @change="handleChange"
  >
    <template v-if="$scopedSlots.label" v-slot:title="{ data }">
      <slot name="label" :data="data" :options="treeData"> </slot>
    </template>
    <a-spin v-if="loading" spinning slot="label" />
    <span slot="title" slot-scope="{ data }" :class="$style.label">
      <x-custom-icon v-if="data.icon" :type="data.icon" />
      <span row="1" :class="$style.text" :title="data.title">
        {{ data.title }}
      </span>
      <form-category-tag
        :class="$style.category"
        :category="getCategory(data.key)"
      />
    </span>
  </x-tree-select>
</template>
<script>
import { Component, Prop, Vue, Model } from 'vue-property-decorator';
import { deepClone, ensureArray, findTree } from '@triascloud/utils';
import {
  ApplicationSearchEnum,
  getReferenceDataSourceList,
} from '@/services/custom-application/application';
import { FormCategoryType } from '@/enum/form';
import FormCategoryTag from './form-category-tag.vue';
import { globalVueI18n } from '@triascloud/i18n';
@Component({
  components: { FormCategoryTag },
})
export default class FormTreeSelect extends Vue {
  /** @name 选中的表单id */
  @Model('bindValue', { type: String | Array }) value;
  /** @name 是否显示（只显示该应用）勾选框 */
  @Prop({ default: false, type: Boolean }) showCurrent;
  /** @name 是否需要过滤掉被数据锁锁定的数据 */
  @Prop({ default: false, type: Boolean }) lock;
  /** @name 要过滤掉的表单id */
  @Prop({ type: [String, Array], default: '' }) formId;
  /** @name 当前应用ID，用于只显示该应用数据 */
  @Prop({ type: [Number, String] }) appId;
  /** @name 自定义数据加载方法 */
  @Prop({ type: [Function, Boolean], default: false }) load;
  /** @name 自定义数据过滤方法 */
  @Prop({ type: Function, default: () => () => true }) filter;
  /** @name 查询类型 */
  @Prop({ type: String, default: ApplicationSearchEnum.FOUND_ALL })
  searchType;
  /** @name 是否需要数据流表单 */
  @Prop({ default: true, type: Boolean }) needDeal;
  /** @name 要展示的类型 */
  @Prop({
    type: Array,
    default: () => [
      FormCategoryType.FORM,
      FormCategoryType.PROCESS_FORM,
      FormCategoryType.DATA_PROCESS,
      FormCategoryType.AGGREGATE_TABLE,
    ],
  })
  category;
  /** @name 找不到提示 */
  @Prop({ default: () => globalVueI18n.translate('company.order.invalid') })
  notFoundLabel;

  @Prop({ type: Boolean }) appSelectable;

  formData = [];
  treeData = [];
  loading = true;
  get parentKeys() {
    return this.treeData.map(item => item.key);
  }
  created() {
    this.init();
  }

  handleChange(v) {
    this.$emit('bindValue', v);
    this.$emit('change');
  }

  async init() {
    this.loading = true;
    try {
      await this.getTableData();
    } catch (error) {
      return error;
    } finally {
      this.loading = false;
    }
    if (this.$refs.treeSelect) {
      this.$refs.treeSelect.checked = true;
    }
  }

  getCategory(key) {
    const tree = findTree(this.formData, item => item.pkId === key);
    const category = tree && tree.category;
    if (
      category &&
      ![
        FormCategoryType.LEVEL_FIRST_GROUP,
        FormCategoryType.LEVEL_SECOND_GROUP,
      ].includes(category)
    ) {
      return category;
    }

    return '';
  }

  get directoryCategory() {
    return [
      FormCategoryType.LEVEL_FIRST_GROUP,
      FormCategoryType.LEVEL_SECOND_GROUP,
    ];
  }

  get computedCategory() {
    return ensureArray(this.category);
  }

  formFilter(list, appId) {
    // 如果只有默认分组，直接展示第二层
    if (
      ensureArray(list).length === 1 &&
      list[0].category === FormCategoryType.LEVEL_FIRST_GROUP
    ) {
      return this.formFilter(list[0].children, appId);
    }
    return ensureArray(list)
      .filter(item => {
        if (!item.category) return false;
        // 目录进行递归过滤
        if (this.directoryCategory.includes(item.category)) {
          item.children = this.formFilter(item.children, appId);
          return !!item.children.length;
        }
        // 不满足prop传入的类型
        if (!this.computedCategory.includes(item.category)) return false;
        // 不满足自定义过滤方法
        if (!this.filter(item)) return false;
        // 过滤掉当前表单id
        if (ensureArray(this.formId).includes(item.pkId)) return false;
        return true;
      })
      .map(item => ({
        title: item.name,
        key: item.pkId,
        parent: appId,
        icon: item.icon,
        checkable: this.category.includes(item.category),
        children: item.children,
        selectable: this.multiple
          ? null
          : this.category.includes(item.category),
      }));
  }

  async getTableData() {
    if (this.load && typeof this.load === 'function') {
      this.treeData = await this.load();
    } else {
      // 默认使用表单数据
      const treeData = await getReferenceDataSourceList(this.searchType, {
        withLock: this.lock,
        needDeal: this.needDeal,
      });
      this.formData = deepClone(treeData);
      this.$emit('getTableData', this.formData);
      this.treeData = ensureArray(treeData)
        .map(item => ({
          key: item.pkId,
          title: item.name,
          selectable: this.multiple ? null : this.appSelectable,
          children: this.formFilter(item.children, item.pkId),
          checkable: this.appSelectable,
        }))
        .filter(app => app.children && app.children.length);
    }
  }

  addExtraOption(option) {
    this.treeData.push(option);
  }

  handleInput(value) {
    this.$emit('input', value);
  }
  handleFilterMethod(item, { checked, keyword }) {
    return (
      (!checked || item.key === this.appId || item.parent === this.appId) &&
      item.title.toLowerCase().includes(keyword.toLowerCase())
    );
  }
}
</script>
<style lang="less" module>
.select {
  :global {
    .x-tree-select-selection__checked {
      // line-height: 20px;
    }
  }
}
.x-tree-select {
  overflow: hidden;
  :global {
    .ant-tree-node-content-wrapper {
      padding-right: 0;
    }
    .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
      width: 6px !important;
    }
    .ant-tree-switcher-noop + .ant-tree-node-content-wrapper {
      width: calc(100% - 6px) !important;
    }
    .x-tree-select-tree {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}
.label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  :global {
    .x-custom-icon {
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.category {
  flex-shrink: 0;
}
</style>
