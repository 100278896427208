<template>
  <div :class="$style.wrapper">
    <div :class="$style.list_ctrl">
      <div :class="$style.list_title">任务明细</div>
      <div :class="$style.list_filter">
        <a-select
          show-search
          :placeholder="$t('hat.managementModal.event.project')"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          v-model="activeCycle"
          @search="() => {}"
          @change="handleChange"
          :class="$style.list_filter_select"
        >
          <a-select-option
            v-for="item in cycleItems"
            :value="item.pkId"
            :key="item.pkId"
            >{{ item.pmName }}</a-select-option
          >
        </a-select>

        <a-select
          show-search
          :placeholder="$t('hat.managementModal.event.project')"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="() => {}"
          @change="handleChange"
          v-model="status"
          :class="$style.list_filter_select"
        >
          <a-select-option
            v-for="item in inspectionStatusOptions"
            :value="item.pkId"
            :key="item.pkId"
            >{{ item.pmName }}</a-select-option
          >
        </a-select>

        <a-input
          placeholder="搜索"
          :class="$style.filter_search"
          v-model="content"
          @blur="handleChange"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
      </div>

      <div :class="$style.list_items">
        <template v-if="deviceTaskList.length">
          <div
            :class="[
              $style.list_item,
              { [$style.activeItem]: activeDeviceIdx === item.deviceId },
            ]"
            @click="handleItemClick(item)"
            v-for="(item, index) in deviceTaskList"
            :key="item.deviceId"
          >
            <div :class="$style.user_info">
              <div :class="$style.userCell">
                <x-oss-image
                  :class="$style.avatar"
                  :ossPath="item.userAvatar"
                />
                <span :class="$style.userName">{{ item.userName }}</span>
              </div>
              <div :class="[$style.status, $style[item.status]]">
                <div :class="$style.statusNum">
                  {{ getInspectionStatus(item.status) }}
                </div>
                <div :class="$style.splitLine"></div>
                <div :class="$style.progress">{{ item.taskProgress }}%</div>
              </div>
            </div>
            <div :class="$style.progressWarps">
              <div :class="$style.progressItems">
                <div
                  :class="[$style.progressItem, $style[item.status]]"
                  v-for="iitem in item.subList"
                  :key="iitem.idxTaskId"
                >
                  <div :class="$style.progressName">
                    <a-tooltip
                      :class="$style.progressNameEllipsis"
                      placement="bottom"
                      :title="iitem.taskName"
                      >{{ iitem.taskNam }}</a-tooltip
                    >

                    <x-icon
                      :class="$style.progressItemINIT"
                      v-if="
                        iitem.status !== 'SUCCESS' && iitem.status !== 'FAIL'
                      "
                      type="tc-icon-clock"
                    />
                    <span
                      v-if="iitem.status === 'SUCCESS'"
                      :class="[
                        $style.progressItemStatus,
                        $style.progressItemSuccess,
                      ]"
                    >
                      <x-icon :class="$style.icon" type="tc-icon-check" />
                    </span>
                    <span
                      v-if="iitem.status === 'FAIL'"
                      :class="[
                        $style.progressItemStatus,
                        $style.progressItemError,
                      ]"
                    >
                      <x-icon :class="$style.icon" type="tc-icon-close" />
                    </span>
                  </div>
                  <div :class="$style.progressBar">
                    <div :class="$style.progressPoint"></div>
                  </div>
                  <div :class="$style.time">
                    {{ index === 0 ? dayjs(item.endTime).format('MM-DD') : '' }}
                    <br />
                    {{ dayjs(iitem.taskTime).format('HH:mm') }}
                  </div>
                </div>
                <div
                  :class="[
                    $style.progressItem,
                    $style.endProgressItem,
                    $style[item.status],
                  ]"
                >
                  <div :class="$style.progressName">
                    <a-tooltip
                      :class="$style.progressNameEllipsis"
                      placement="bottom"
                      title="结束"
                      >结束</a-tooltip
                    >
                  </div>
                  <div :class="[$style.progressBar, $style.endProgressBar]">
                    <div :class="$style.progressPoint"></div>
                  </div>
                  <div :class="$style.time">
                    {{ dayjs(item.endTime).format('MM-DD') }} <br />
                    {{ dayjs(item.endTime).format('HH:mm') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else>
          <EmptyContent type="list" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Displacement from '@/views/iot/electric-box/device-configuration/displacement.vue';
import { getCycleDateById, getDeviceTaskList } from '@/services/smart-hat/task';
import dayjs from 'dayjs';
import EmptyContent from '@/components/empty-content/empty-content.vue';
// eslint-disable-next-line no-unused-vars
@Component({
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  components: { EmptyContent, Displacement },
})
export default class taskDetailList extends Vue {
  @Prop({ type: String, default: '' }) inspectionId;
  @Watch('inspectionId', { immediate: true })
  async handleInspectionIdChange() {
    const { records } = await getCycleDateById({
      inspectionId: this.inspectionId,
      current: 1,
      size: 999,
    });
    const DaysEnum = {
      0: '周末',
      1: '周一',
      2: '周二',
      3: '周三',
      4: '周四',
      5: '周五',
      6: '周六',
    };

    this.cycleItems = records.map(item => {
      const { dateList, cycleNum } = item;
      const days = dateList
        .map(item => {
          return DaysEnum[dayjs(item).day()];
        })
        .join('，');
      return {
        pmName: `周期${item.cycleNum}（${days}）`,
        pkId: cycleNum,
      };
    });
    this.activeCycle = this.cycleItems[0].pkId;
    this.handleChange();
  }
  cycleItems = [];
  activeCycle = null;
  status = '';
  content = '';
  activeDeviceIdx = '';
  inspectionStatusOptions = [
    {
      pmName: '进行中',
      pkId: 'UNDER_WAY',
    },
    {
      pmName: '未完成',
      pkId: 'FAIL',
    },
    {
      pmName: '未开始',
      pkId: 'INIT',
    },
    {
      pmName: '已完成',
      pkId: 'SUCCESS',
    },
    {
      pmName: '待执行',
      pkId: 'WAIT',
    },
  ];
  getInspectionStatus(status = 'UNDER_WAY') {
    return this.inspectionStatusOptions.find(item => item.pkId === status)
      .pmName;
  }
  deviceTaskList = [];
  async handleChange() {
    console.log('change');
    const params = {
      content: this.content,
      cycleNum: this.activeCycle,
      inspectionId: this.inspectionId,
      status: this.status,
    };

    this.deviceTaskList = await getDeviceTaskList(params);
    console.log('deviceTaskList', this.deviceTaskList);
  }
  handleItemClick(item) {
    this.activeDeviceIdx = item.deviceId;
    this.$emit('handleDetail', item);
  }
}
</script>
<style lang="less" module>
:global {
  .ant-select-dropdown {
    width: auto !important;
  }
}
.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  overflow: hidden;
  height: 100%;
  z-index: 1;

  .list_ctrl {
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    width: 346px;
    border-radius: 10px;
    background: #fff;
    .list_title {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: left;
      padding: 10px 10px 20px 10px;
    }
    .list_filter {
      display: flex;
      align-content: center;
      :global {
        .ant-select + .ant-select {
          margin-left: 6px;
        }
        .ant-select-selection__rendered {
          margin-left: 11px;
          margin-right: 0 !important;
        }
      }
      .filter_search {
        width: 142px;
        margin-left: 6px;
      }
      .list_filter_select {
        width: 86px;
      }
    }
  }

  .list_items {
    padding-top: 20px;
    height: calc(100% - 80px);
    overflow-y: scroll;
    .activeItem {
      border: 1px solid #4771ff !important;
    }
    .list_item {
      border: 1px solid transparent;
      margin-bottom: 20px;
      width: 326px;
      height: 154px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px #e1e1e1, 0px 0px 13px 0px #e1e1e1;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 10px;
      .user_info {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .userCell {
          display: flex;
          align-items: center;
          .avatar {
            border-radius: 18px;
            width: 36px;
            height: 36px;
            margin-right: 10px;
          }
          .userName {
            font-size: 14px;
          }
        }
        .status {
          width: 104px;
          height: 28px;
          border-radius: 149px;
          background: #eee;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333;
          padding: 0 10px;
          .splitLine {
            width: 1px;
            height: 16px;
            background: #dddddd;
            box-shadow: 0px 0px 8px 0px #e1e1e1;
            border-radius: 1px;
            margin: 0 8px;
          }
          .statusNum {
            font-size: 14px;
            color: #333333;
          }
          .progress {
            font-size: 12px;
          }
        }

        .notStarted {
          color: #333;
          background: #eeeeee;
          .splitLine {
            background: #dddddd;
          }
        }

        .WAIT {
          color: #ffa010;
          background: rgba(255, 160, 16, 0.1);
          .statusNum {
            color: #ffa010;
          }
          .splitLine {
            background: rgba(255, 160, 16, 0.2);
          }
        }
        .UNDER_WAY {
          color: #4771ff;
          background: rgba(71, 113, 255, 0.1);
          .statusNum {
            color: #4771ff;
          }
          .splitLine {
            background: rgba(71, 113, 255, 0.2);
          }
        }
        .SUCCESS {
          color: #1cec16;
          background: rgba(28, 236, 22, 0.1);
          .statusNum {
            color: #1cec16;
          }
          .splitLine {
            background: rgba(28, 236, 22, 0.2);
          }
        }
        .FAIL {
          color: rgb(246, 0, 0);
          background: rgba(246, 0, 0, 0.1);
          .statusNum {
            color: rgb(246, 0, 0);
          }
          .splitLine {
            background: rgba(246, 0, 0, 0.2);
          }
        }
      }
      .progressWarps {
        overflow-y: hidden;
      }
      .progressItems {
        margin-top: 20px;
        width: max-content;
        .WAIT {
          .progressPoint {
            background: #ffa010 !important;
          }
          .progressBar {
            background: #ffa010 !important;
          }
        }
        .endProgressItem {
          min-width: 40px !important;
        }

        .progressItem {
          display: inline-block;
          overflow-y: scroll;
          min-width: 100px;
          height: 70px;

          .UNDER_WAY {
            color: #4771ff;
            background: rgba(71, 113, 255, 0.1);
            .statusNum {
              color: #4771ff;
            }
            .splitLine {
              background: rgba(71, 113, 255, 0.2);
            }
          }
          .SUCCESS {
            color: #1cec16;
            background: rgba(28, 236, 22, 0.1);
            .statusNum {
              color: #1cec16;
            }
            .splitLine {
              background: rgba(28, 236, 22, 0.2);
            }
          }
          .FAIL {
            color: rgb(246, 0, 0);
            background: rgba(246, 0, 0, 0.1);
            .statusNum {
              color: rgb(246, 0, 0);
            }
            .splitLine {
              background: rgba(246, 0, 0, 0.2);
            }
          }
          .progressItemINIT {
            color: #ffb039;
          }
          .progressItemStatus {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 14px;
            height: 14px;
            border-radius: 7px;
            color: #fff;
            .icon {
              font-size: 10px;
              font-weight: bold;
            }
          }
          .progressItemSuccess {
            background: rgba(28, 236, 22, 1) !important;
          }
          .progressItemError {
            background: rgba(246, 0, 0, 1) !important;
          }
          .progressNameEllipsis {
            vertical-align: bottom;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 130px;
            min-width: 40px;
          }
          .icon {
            margin-left: 3px;
            margin-right: 10px;
          }
          .time {
            font-size: 12px;
            color: #333333;
            line-height: 14px;
            text-shadow: 0px 0px 8px #e1e1e1;
          }
          .progressBar {
            position: relative;
            width: 100%;
            height: 2px;
            background: #1cec16;
            box-shadow: 0px 0px 8px 0px #e1e1e1;
            border-radius: 6px;
            margin: 12px 0;
          }
          .endProgressBar {
            background: transparent !important;
            box-shadow: none;
          }
          .progressPoint {
            position: absolute;
            top: -3px;
            width: 8px;
            height: 8px;
            background: #1cec16;
            box-shadow: 0px 0px 8px 0px #e1e1e1;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
</style>
