<template>
  <div :class="$style.mapWrap">
    <div id="boxMap" :class="$style.boxMap"></div>

    <div :class="$style.title">
      巡检任务实时动态
    </div>
    <div :class="$style.deviceInfo" ref="deviceInfo">
      <div class="deviceInfoWarp">
        <div class="deviceTitle">
          <div>
            <x-oss-image class="avatar" :ossPath="activeDevice.userAvatar" />
          </div>
          <div class="deviceDetail">
            <div class="deviceDetailWarp">
              <span class="deviceName">
                {{ activeDevice.userName }}
              </span>
              <span class="deviceStatus">
                {{ statusType[activeDevice.status] }}
              </span>
              <span class="deviceOnline">
                <div class="net">
                  <span class="netTxt">
                    {{ LOCATEMODE_KEY[activeDevice.locateMode] }}
                  </span>
                  <a-icon
                    v-if="!LOCATEMODE_KEY[activeDevice.locateMode] === 'WIFI'"
                    type="wifi"
                    class="wifiIcon"
                  />

                  <div v-else :class="['lnWrap', networkColor]">
                    <span class="ln ln1"></span>
                    <span class="ln ln2"></span>
                    <span class="ln ln3"></span>
                    <span class="ln ln4"></span>
                  </div>
                </div>
              </span>
            </div>
            <div class="subTitle">
              <span>{{ time }}</span>
              <span class="statusWarp" style="margin-left: 10px">
                <x-icon type="tc-icon-unbonnet" />
                <span> {{ hatStatus }}</span>
              </span>
              <span class="statusWarp">
                <x-icon type="tc-icon-gyroscope" />
                <span> {{ gyroscope }}</span>
              </span>
              <span class="statusWarp">
                <x-icon type="tc-icon-battery" />
                <span> {{ electricity }}</span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="fileTitle">
              <div>
                <span class="fileSubTit">
                  最近文件
                </span>
                <span class="fileTime"> </span>
              </div>
              <div class="more" @click="handleDeviceItem(activeDevice)">
                >
              </div>
            </div>
            <div class="file-items">
              <template v-if="activeDevice.files && activeDevice.files.length">
                <div
                  @click="handlePreview(item)"
                  class="file-item"
                  v-for="(item, idx) in activeDevice.files"
                  :key="idx"
                >
                  <x-oss-image
                    class="file-img"
                    basePath="/oss/iot/oss"
                    v-if="item.type === 'PHOTO'"
                    :ossPath="item.path"
                  />

                  <VideoThumbnail
                    v-if="item.type === 'VIDEO'"
                    basePath="/oss/iot/oss"
                    class="fileVideo"
                    :ossPath="item.path"
                    :hasClick="false"
                  />
                  <div class="iconBox" v-if="item.type === 'AUDIO'">
                    <x-icon type="tc-color-file-voice" class="icon" />
                  </div>

                  <div class="iconBox" v-if="item.type === 'OTHER'">
                    <x-icon type="tc-color-file-unknown" class="icon" />
                  </div>
                </div>
              </template>

              <div class="file-items-empty" v-else>
                暂无数据
              </div>
            </div>
          </div>

          <div class="optionWarp">
            <div class="optionBtn" @click="handleVideo('audio')">
              <span class="optionIcon">
                <a-icon type="audio" theme="filled" />
              </span>
              <span class="optionName">语音</span>
            </div>
            <a-dropdown>
              <div class="optionBtn">
                <span class="optionIcon">
                  <a-icon type="video-camera" theme="filled" />
                </span>
                <span class="optionName">视频</span>
              </div>
              <a-menu slot="overlay">
                <a-menu-item @click="handleVideo('video')">
                  {{ $t('hat.talk.call') }}
                </a-menu-item>
                <a-menu-item
                  @click="handleMonitor"
                  v-if="$p.action('IOT_HELMET_MONITOR_MODE', '/hat/device')"
                >
                  {{ $t('hat.talk.patrol') }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>

            <div class="optionBtn" @click="showFormModel()">
              <span class="optionIcon">
                <x-icon class="icon" type="tc-icon-notice" />
              </span>
              <span class="optionName">广播</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.test" ref="test">
      <div class="infoWindowWarp">
        <div class="page">
          <!--          <x-icon type="tc-icon-delete" />-->
          <a-icon class="prev" type="down" @click="handlePrev" />

          {{ activeProjectIndex + 1 }}/{{ activeProjectInfo.task.length }}
          <a-icon class="next" type="down" @click="handleNext" />
        </div>
        <div class="item">
          <div class="label">
            任务项：
          </div>
          <div class="content">
            {{ activeProjectInfo.task[activeProjectIndex].taskName }}
          </div>
        </div>
        <div class="item">
          <div class="label">
            时间：
          </div>
          <div class="content">
            {{ activeProjectInfo.task[activeProjectIndex].startTime }}
          </div>
        </div>
        <div class="item">
          <div class="label">
            地点：
          </div>
          <div class="content">
            {{ activeProjectInfo.task[activeProjectIndex].geofenceName }}
          </div>
        </div>
        <div class="item">
          <div class="label">
            要求：
          </div>
          <div class="content">
            <x-icon
              v-if="activeProjectInfo.task[activeProjectIndex].photoNumber"
              class="icon"
              type="tc-icon-camera-filled"
            />
            <x-icon
              v-if="activeProjectInfo.task[activeProjectIndex].videoNumber"
              class="icon"
              type="tc-icon-video-filled"
            />
            <x-icon
              v-if="activeProjectInfo.task[activeProjectIndex].formNumber"
              class="icon"
              type="tc-icon-form-filled"
            />
          </div>
        </div>
        <div class="item">
          <div class="label">
            执行设备：
          </div>
          <div class="content">
            -
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.tips">
      任务状态：进行中

      <a-tooltip :class="$style.progressNameEllipsis" placement="bottom">
        <template slot="title">
          <div>
            1、任务项开始前30分钟可查看设备实时位置；
          </div>
          <div>
            2、任务项结束后无法查看设备位置；
          </div>
        </template>

        <x-icon type="tc-icon-warning"
      /></a-tooltip>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  drawCharts,
  formatToApi,
  funAMap,
} from '../electron-fence/components/util';
import { getDeviceAndGeofence } from '@/services/smart-hat/task';
import { getGeoFenceZoneList } from '@/services/smart-hat/screen';
import * as turf from '@turf/turf';
import {
  getDeviceDetail,
  getDeviceProperty,
} from '@/services/smart-hat/device';
import { getFileList } from '@/services/smart-hat/file';
import { statusType } from '../enum';
import dayjs from 'dayjs';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import VideoThumbnail from '@/views/hat/device/manage-model/file/video-thumbnail.vue';
import {
  createFormModal,
  createModal,
  Preview,
} from '@triascloud/x-components';
import { queryDevice } from '@/services/smart-hat/device-management';
import GroupTalk from '@/views/hat/device/components/group-talk.vue';
import MonitorConfirm from '@/views/hat/device/manage-model/person-info/monitor-confirm.vue';
import { delay } from '@triascloud/utils';
import BroadcastForm from '@/views/hat/broadcast/components/broadcast-form.vue';
import ManageModel from '@/views/hat/device/manage-model/index.vue';

/** @name 视频通话的模式：1普通；2无感巡查；3有感巡查 */
export const VIDEO_MODE = {
  /** @name 正常音视频通话 */
  NORMAL: 'NORMAL',
  /** @name 无感巡查 */
  SENSELESS_MONITORING: 'SENSELESS_MONITORING',
  /** @name 有感巡查 */
  SENSORY_MONITORING: 'SENSORY_MONITORING',
};

@Component({
  components: {
    EmptyContent,
    VideoThumbnail,
    GroupTalk,
    BroadcastForm,
    ManageModel,
  },
  computed: {
    statusType() {
      return statusType;
    },
  },
})
export default class FileMap extends Vue {
  @Prop({ type: String, default: '' }) inspectionId;
  @Watch('inspectionId', { immediate: true })
  async handleInspectionIdChange() {
    const { devices, geofences } = await getDeviceAndGeofence({
      inspectionId: this.inspectionId,
    });
    this.geofences = geofences;
    let FenceList = geofences.map(async item => {
      return await this.getGeofence(item.geofenceId);
    });
    const res = await Promise.all(FenceList);
    const flatRes = res.flat(Infinity);

    this.zoneArray = formatToApi(flatRes);
    if (this.zoneArray.length) {
      if (this.zoneArray[0].type === 'circle') {
        this.map.setCenter(this.zoneArray[0].center);
      } else {
        this.map.setCenter(this.zoneArray[0].path[0]);
      }
    }
    this.chartList = drawCharts(this.zoneArray, this.AMap, (...args) =>
      this.mouseHoverEvent.call(null, ...args, this.map),
    );

    //   (...args) =>
    //     mouseEvent.call(null, ...args, this.map),
    // )

    this.map.add(this.chartList);
    this.map.setZoom(16);
    let deviceList = devices.map(item => {
      return this.createMarker(item);
    });
    deviceList = deviceList.map(item => {
      const marker = item;
      marker.on('mouseover', () => {
        this.infoWindow = new this.AMap.InfoWindow({
          content: this.$refs.test, //传入字符串拼接的 DOM 元素
          anchor: 'center',
        });
      });
      return marker;
    });

    this.map.add(deviceList);
    // const marker = this.createMarker(this.map.getCenter());
  }

  mouseHoverEvent(AMap, chart, mapObject, opts = {}) {
    chart.on('mouseover', event => {
      let extData = event.target.getExtData();
      let _geofenceId_ = extData._geofenceId_;
      const activeProjectInfo = this.geofences.find(item => {
        return item.geofenceId === _geofenceId_;
      });
      this.activeProjectInfo = activeProjectInfo;
      let position = [];
      if (Object.keys(opts).length === 0) {
        extData['_area_'] = event.target.getArea();
      }
      if (event.target instanceof AMap.Polygon) {
        const array = [...event.target._opts.path, event.target._opts.path[0]];
        const polygon = turf.polygon([array]);
        const centroid = turf.centroid(polygon);
        position = centroid.geometry.coordinates;
      } else {
        position = event.target._opts.center;
      }
      // this.infoWindow = new this.AMap.InfoWindow({
      //   content: this.$refs.test, //传入字符串拼接的 DOM 元素
      //   position,
      //   anchor: 'center',
      // });

      // const textField = new AMap.Marker({
      //   content: this.$refs.test,
      //   position,
      //   offset: new AMap.Pixel(-120, -180),
      //   zIndex: 101,
      //   ...opts,
      // });
      const textField = new this.AMap.InfoWindow({
        content: this.$refs.test, //传入字符串拼接的 DOM 元素
        anchor: 'center',
        offset: new AMap.Pixel(0, 100),
        zIndex: 101,
      });
      textField.on('mouseout', () => {
        event.target.textField &&
          mapObject.remove(event.target.textField) &&
          (event.target.textField = null);
      });

      event.target.textField = textField;

      textField.open(this.map, position);

      // mapObject.add(event.target.textField);
    });
    // chart.on('mouseout', event => {
    //   event.target.textField &&
    //     mapObject.remove(event.target.textField) &&
    //     (event.target.textField = null);
    // });
  }
  zoneArray = [];
  chartList = [];
  async getGeofence(id) {
    const param = {
      geofenceIds: id,
    };
    return await getGeoFenceZoneList(param);
  }

  async showFormModel() {
    try {
      const record = {
        autoPlay: false,
        type: 'TEXT',
        broadcastStatus: '',
        broadcastTime: this.$moment(new Date())
          .add(1, 'minute')
          .format('YYYY-MM-DD HH:mm:ss'),

        content: '',
        idxSaveId: [this.activeDevice.deviceId],
        receiptStatus: false,
        replyStatus: false,
        projectId: undefined,
        saveType: 'DEVICE',
      };
      const result = await createFormModal(
        () => (
          <BroadcastForm
            editData={record}
            operationType={'copy'}
            submitType={this.submitType}
          />
        ),
        {
          width: 860,
          title: this.$t('hat.broadcast.form.editBroadcast'),
          maskClosable: false,
          footer: (h, submit, cancel) => [
            <AButton
              ghost
              onClick={() => {
                cancel();
              }}
            >
              {this.$t('msg.cancel')}
            </AButton>,
            <AButton
              type="primary"
              ghost
              onClick={async () => {
                this.submitType = 'copy';
                await delay();
                submit();
              }}
            >
              {this.$t('hat.broadcast.form.saveDraft')}
            </AButton>,
            <AButton
              type="primary"
              onClick={async () => {
                this.submitType = 'confirm';
                await delay();
                submit();
              }}
            >
              {this.$t('msg.confirm')}
            </AButton>,
          ],
        },
      );
      if (result) {
        this.$refs.tableRef.refresh();
      }
    } catch {
      return false;
    }
  }

  mounted() {
    this.addMap();
  }
  activeProjectIndex = 0;
  activeProjectInfo = {
    task: [{}],
  };
  geofences = [];
  urlList = [];
  idList = [];
  async handlePreview(item) {
    Preview.createModal({
      urls: item.path,
      ossBasePath: '/oss/iot/oss',
    });
  }

  handleDeviceItem(item) {
    createModal(() => <ManageModel record={item} defaultActiveKey={'file'} />, {
      width: 1280,
      title: this.$t('hat.deviceManagement.manage'),
      maskClosable: false,
      className: this.$style.ManageModel,
    });
  }

  beInvitedChannelId = '';
  videoType = 'video';
  async handleVideo(type) {
    if (this.activeDevice.status !== 'ONLINE') {
      this.$message.warning('当前设备不在线！');
      return;
    }
    if (this.activeDevice.type !== 'PLUS') {
      this.$message.warning('该版本不支持视频语音通话！');
      return;
    }
    this.videoType = type;
    this.videoMode = VIDEO_MODE.NORMAL;
    const queryResult = await this.monitorCheck();
    if (!queryResult.status) {
      return false;
    }
    await this.handleSingleTalk();
  }
  /** @name 设备是否巡查中... */
  async monitorCheck() {
    this.beInvitedChannelId = '';
    const data = await queryDevice(`DEVICE_${this.activeDevice.deviceName}`);
    if (data && data.channelId) {
      this.beInvitedChannelId = data.channelId;
      if (
        data.mode === VIDEO_MODE.SENSELESS_MONITORING ||
        data.mode === VIDEO_MODE.SENSORY_MONITORING
      ) {
        this.videoMode = data.mode;
        if (!this.$p.action('IOT_HELMET_MONITOR_MODE', '/hat/device')) {
          this.$message.warning('设备通话中，请稍后再拨！');
          return {
            val: data,
            status: false,
          };
        }
        const checkResult = await createFormModal(
          () => (
            <div class={this.$style.mode1Wrap}>
              <p class={this.$style.mb}>{this.$t('hat.talk.tip.title')}</p>
              <p>{this.$t('hat.talk.tip.attention')}</p>
              <p>1、您的麦克风会默认关闭，开启后设备端可正常收听到您的语音；</p>
              <p>2、请确保设备所处场允许拍摄，且不侵犯现场人员的隐私；</p>
              <p>3、巡查模式需最新设备固件支持，否则将按正常通话处理；</p>
              <p>
                4、请确保使用场景的合规、合法性，三叠不对由此行为承担任何责任；
              </p>
            </div>
          ),
          {
            width: 550,
            title: '操作确认',
          },
        );
        if (checkResult) {
          return {
            val: data,
            status: true,
          };
        }
      } else {
        this.$message.warn('设备通话中，请稍后再拨！');
        return {
          val: data,
          status: false,
        };
      }
    }
    return {
      val: data,
      status: true,
    };
  }
  async handleSingleTalk() {
    let groupTalk = [];
    if (!this.beInvitedChannelId) {
      groupTalk = [
        {
          deviceUserId: 'DEVICE_' + this.activeDevice.deviceName,
          deviceId: this.activeDevice.deviceId,
          deviceName: this.activeDevice.deviceName,
          avatar: this.activeDevice.userAvatar,
          userName: this.activeDevice.userName,
          status: '',
          enumMsg: '',
          isCall: false,
          resolution: 720,
          screenMode: 0,
          videoType: this.videoType,
          userType: 'DEVICE',
          muted: false,
          remoteVideoTrack: null,
          remoteAudioTrack: null,
          isFullScreen: false,
          lng: 0,
          lat: 0,
        },
      ];
    }
    const talkModal = createModal(
      () => (
        <GroupTalk
          arr={groupTalk}
          videoMode={this.videoMode}
          videoType={this.videoType}
          beInvitedChannelId={this.beInvitedChannelId}
          close={() => talkModal.handleClose()}
        />
      ),
      {
        width: '100vw',
        closable: false,
        wrapClassName: this.$style.groupTalkWrap,
      },
    );
  }
  async handleMonitor() {
    this.videoType = 'video';
    const queryResult = await this.monitorCheck();
    if (queryResult.status) {
      if (!queryResult.val) {
        const mode1 = await createFormModal(() => <MonitorConfirm />, {
          width: 550,
          title: '操作确认',
        });
        if (mode1) {
          this.videoMode = mode1;
        }
      }
      await this.handleSingleTalk();
    }
  }
  beforeDestroy() {
    this.$store.commit('home/setBigScreen', false);
  }

  fenceList = [];

  @Prop() center;
  map = null;
  AMap = null;
  infoWindow = null;

  async addMap() {
    const AMap = await funAMap({
      AMapUI: {
        version: '1.1',
        plugins: [],
      },
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    const lnglat = new AMap.LngLat(116.397, 39.918);

    this.map = new AMap.Map('boxMap', {
      zoom: 15,
      center: lnglat,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
  }
  handlePrev() {
    this.activeProjectIndex =
      this.activeProjectIndex === 0 ? 0 : this.activeProjectIndex - 1;
  }
  handleNext() {
    this.activeProjectIndex =
      this.activeProjectIndex === this.activeProjectInfo.task.length - 1
        ? this.activeProjectInfo.task.length - 1
        : this.activeProjectIndex + 1;
  }

  NETWORK_KEY = {
    DIFFERENCE: 'DIFFERENCE',
    MIDDLE: 'MIDDLE',
    GOOD: 'GOOD',
    EXCELLENT: 'EXCELLENT',
  };

  /** @name 设备联网方式 */
  LOCATEMODE_KEY = {
    FOUR_G: '4G',
    THREE_G: '3G',
    TWO_G: '2G',
    WIFI: 'WIFI',
  };

  get networkColor() {
    if (!this.activeDevice.networkSignal) return '';
    if (this.activeDevice.networkSignal === this.NETWORK_KEY.DIFFERENCE) {
      return 'a1';
    } else if (this.activeDevice.networkSignal === this.NETWORK_KEY.MIDDLE) {
      return 'a2';
    } else if (this.activeDevice.networkSignal === this.NETWORK_KEY.GOOD) {
      return 'a3';
    } else {
      return 'a4';
    }
  }
  timeFormat(current = 0, target = 0) {
    /** @name 1天的毫秒数 */
    const diffDay = 86400000;
    /** @name 1小时的毫秒数 */
    const dH = 3600000;
    /** @name 1分钟的毫秒数 */
    const dM = 60000;

    const diff = current - target;

    let result = '';
    if (diff >= diffDay) {
      const day = Math.floor(diff / diffDay);
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: day,
        unit: this.$t('screen.days'),
      });
    } else if (diff > dH) {
      const hour = dayjs(current)
        .diff(target, 'hour', true)
        .toFixed(1);
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: hour,
        unit: this.$t('hat.screen.hours'),
      });
    } else if (diff > dM) {
      const minute = dayjs(current).diff(target, 'minute');
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: minute,
        unit: this.$t('hat.managementModal.setting.hint.rule.minutes'),
      });
    } else {
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: '1',
        unit: this.$t('hat.managementModal.setting.hint.rule.minutes'),
      });
    }
    return result;
  }
  /** @name 状态类型 */
  STATUS_KEY = {
    NOT_SUPPORTED: this.$t('hat.managementModal.status.unsupported'),
    NORMAL: this.$t('hat.clockRecord.normal'),
    FAULT: this.$t('screen.faults'),
    NOT_CONNECTED: this.$t('hat.managementModal.status.disconnected'),
  };
  takeOffHatEnum = {
    '1': this.$t('hat.managementModal.status.capping'),
    '2': this.$t('hat.managementModal.status.uncapping'),
  };
  gyroscopeEnum = {
    '1': this.$t('hat.managementModal.status.standstill'),
    '2': this.$t('hat.managementModal.status.moving'),
    '3': this.$t('hat.managementModal.status.falling'),
    '4': this.$t('hat.managementModal.status.dropping'),
  };
  showGyroscope(gyroscope) {
    const status = gyroscope.status;
    if (status == 'NORMAL') {
      return this.gyroscopeEnum[gyroscope.val] || gyroscope.val;
    } else {
      return this.STATUS_KEY[gyroscope.status];
    }
  }
  showHat(takeOffHat = '') {
    if (!takeOffHat) return;
    const status = takeOffHat.status;
    if (status == 'NORMAL') {
      return this.takeOffHatEnum[takeOffHat.val] || takeOffHat.val;
    } else {
      return this.STATUS_KEY[takeOffHat.status];
    }
  }

  devicesDetail = {};
  activeDevice = {};
  time = '';
  hatStatus = '';
  gyroscope = '';
  electricity = '';
  createMarker(item) {
    const marker = new this.AMap.Marker({
      position: [item.lng, item.lat],
      zIndex: 99,
      extData: {
        _id: item.deviceId,
      },
    });

    marker.on('mouseover', async () => {
      if (!this.devicesDetail[item.deviceId]) {
        const res = await getDeviceDetail(item.deviceId);
        const { records } = await getFileList({
          deviceId: item.deviceId,
          listType: 'DEVICE_LOCAL',
        });

        const urlList = [];
        const idList = [];
        records.forEach(v => {
          urlList.push(v.path);
          idList.push({
            id: v.fileId,
            relatedFileList: v.relatedFilePathList,
            videoTrackModel: v.videoTrackModel,
            videoTrackModelWatermark: v.videoTrackModelWatermark,
          });
        });
        this.urlList = urlList;
        this.idList = idList;

        const property = await getDeviceProperty(item.deviceId);
        this.devicesDetail[res.deviceId] = res;
        this.devicesDetail[res.deviceId]['files'] = records.slice(0, 4);
        this.devicesDetail[res.deviceId]['property'] = property;
      }
      this.activeDevice = this.devicesDetail[item.deviceId];
      console.log('activeDevice', this.activeDevice);

      if (this.activeDevice.property) {
        this.hatStatus = this.showHat(this.activeDevice.property.takeOffHat);
        this.gyroscope = this.showGyroscope(
          this.activeDevice.property.gyroscope,
        );
        this.electricity = this.activeDevice.property.electricity
          ? `${this.activeDevice.property.electricity}%`
          : '-';
      }
      if (this.activeDevice.lastOnlineTime) {
        this.time = this.timeFormat(
          Date.now(),
          this.activeDevice.lastOnlineTime,
        );
      }

      const infoWindow = new this.AMap.InfoWindow({
        content: this.$refs.deviceInfo, //传入字符串拼接的 DOM 元素
        offset: new this.AMap.Pixel(0, -30),
        zIndex: 999,
      });
      infoWindow.open(this.map, [item.lng, item.lat]);

      infoWindow.on('mouseout', () => {
        // infoWindow.close();
      });
    });

    return marker;
  }
}
</script>
<style lang="less" module>
.mapWrap {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  :global {
    .ant-select {
      background-color: var(--main-bg);
      border-radius: 4px;
    }
    .amap-info-content {
      padding: 0;
      background: transparent;
    }
    .amap-info-outer {
      padding: 0;
      background: transparent;
    }
    .amap-info-close {
      display: none;
    }
  }
  .screen {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
  }
  .icon {
    font-size: 30px;
    color: var(--primary);
  }

  .deviceInfo {
    :global {
      .deviceInfoWarp {
        background: #fff;
        position: relative;
        width: 325px;
        height: 282px;
        border-radius: 6px;
        .deviceTitle {
          background: #e2e9ff;
          border-radius: 0 0 6px 6px;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .avatar {
            width: 46px;
            height: 46px;
            border: 2px solid #4771ff;
            border-radius: 23px;
            margin-right: 10px;
          }
        }
        .fileSubTit {
          color: #999999;
          font-size: 14px;
          line-height: 14px;
        }
        .fileTime {
          color: #999999;
          display: inline-block;
          font-size: 12px;
        }
        .fileTitle {
          padding: 10px 14px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          line-height: 14px;
          .more {
            font-size: 18px;
            cursor: pointer;
            color: #999999;
          }
        }
        .subTitle {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
          .statusWarp {
            display: inline-block;
            width: 45px;
            text-align: center;
          }
        }
        .optionWarp {
          display: grid;
          padding: 20px 17px;
          grid-template-columns: repeat(3, 33.3%);
          grid-template-rows: repeat(1, 40px);
          gap: 5px;
          .optionBtn {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .optionIcon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            background: #f9f9f9;
            margin-right: 10px;
            .icon {
              font-size: 28px;
              color: #333333;
            }
            .optionName {
              font-size: 24px;
              color: #333333;
              line-height: 24px;
            }
          }
        }

        .deviceDetail {
          .deviceDetailWarp {
            display: flex;
            align-items: center;
          }

          .deviceName {
            font-size: 20px;
            color: #333333;
            line-height: 24px;
          }
          .deviceStatus {
            box-shadow: 0px 0px 6px 0px #e1e1e1;
            background: #4771ff;
            border-radius: 8px;
            font-size: 12px;
            color: #ffffff;
            line-height: 18px;
            height: 18px;
            margin: 0 20px 0 5px;
            padding: 0 3px;
          }
          .deviceOnline {
            font-weight: 600;
            font-size: 12px;
            transform: scale(0.8);
            color: #4771ff;
            line-height: 12px;
            .net {
              position: relative;
              color: var(--primary);
              display: flex;
              align-items: center;
              .netTxt {
                position: absolute;
                top: -4px;
                left: 0;
                font-size: 10px;
              }
              .wifiIcon {
                font-size: 20px;
                margin-top: 4px;
              }
              .lnWrap {
                display: flex;
                transform: rotateX(180deg);
                &.a1 {
                  .ln1 {
                    background-color: var(--font-active);
                  }
                }
                &.a2 {
                  .ln1,
                  .ln2 {
                    background-color: var(--font-active);
                  }
                }
                &.a3 {
                  .ln1,
                  .ln2,
                  .ln3 {
                    background-color: var(--font-active);
                  }
                }
                &.a4 {
                  .ln1,
                  .ln2,
                  .ln3,
                  .ln4 {
                    background-color: var(--font-active);
                  }
                }
                .ln {
                  width: 6px;
                  height: 6px;
                  background-color: var(--primary-30);
                  display: inline-block;
                  border-radius: 4px;
                }
                .ln + .ln {
                  margin-left: 2px;
                }
                .ln2 {
                  height: 10px;
                }
                .ln3 {
                  height: 15px;
                }
                .ln4 {
                  height: 20px;
                }
              }
            }
          }
        }
        .file-items-empty {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          width: 100%;
        }
        .file-items {
          display: flex;
          padding: 0 10px;
          height: 70px;
          .file-item {
            .file-img {
              width: 70px;
              height: 70px;
              border-radius: 8px;
              object-fit: cover;
              cursor: pointer;
            }
            .fileVideo {
              width: 70px;
              height: 70px;
              cursor: pointer;
              overflow: hidden;
              border-radius: 8px;
            }
            .iconBox {
              width: 70px;
              height: 70px;
              border-radius: 8px;
              background: var(--block-bg);
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px 0px 2px 0px #e1e1e1;
              .icon {
                font-size: 46px;
              }
            }
            margin-right: 6px;
            img {
              height: 70px;
              width: 70px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
  .test {
    :global {
      .infoWindowWarp {
        position: relative;
        width: 284px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 10px;
        padding: 10px 20px;
        box-sizing: border-box;
        .page {
          position: absolute;
          right: 10px;
          top: 10px;
          color: #fff;
          .prev {
            cursor: pointer;
            transform: rotate(90deg);
          }
          .next {
            cursor: pointer;
            transform: rotate(270deg);
          }
        }
        .item {
          display: flex;
          line-height: 20px;
          margin-bottom: 10px;

          .label {
            font-size: 14px;
            color: #999999;
          }
          .content {
            font-size: 14px;
            color: #fff;
            .icon {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .title {
    position: absolute;
    left: 50%;
    top: 10px;
    width: 162px;
    height: 41px;
    text-align: center;
    background: #4771ff;
    border-radius: 10px;
    opacity: 0.7;
    font-size: 16px;
    color: #ffffff;
    line-height: 41px;
    z-index: 1;
  }
  .tips {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 182px;
    height: 41px;
    background: #000000;
    border-radius: 10px;
    opacity: 0.5;
    z-index: 1;
    font-size: 16px;
    color: #ffffff;
    line-height: 41px;
    text-align: center;
  }
  .boxMap {
    height: 100%;
  }
  .topPart {
    position: absolute;
    z-index: 9999;
    top: 10px;
    left: 10px;
    display: flex;
  }
}
.groupTalkWrap {
  :global {
    .ant-modal-header {
      padding: 0;
      border: none;
      background: transparent;
      border-radius: 0;
    }
    .ant-modal-content {
      height: 100vh;
      max-height: 100vh;
      max-width: 100vw;
      border-radius: 0;
    }
    .ant-modal-body {
      padding: 0;
      margin: 0;
    }
  }
}
.mode1Wrap {
  font-size: 14px;
  color: var(--font);
  p {
    font-size: 12px;
    color: var(--font-sub);
  }
  .mode1Tip {
    font-size: 12px;
    margin-left: 66px;
    color: var(--font-sub);
    margin-bottom: 12px;
  }
  .mode1Top {
    margin-bottom: 4px;
  }
  .mode1Title {
    font-size: 14px;
    margin-right: 10px;
  }
  .mb {
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
}
.ManageModel {
  :global {
    .ant-model-body {
      padding: 0;
      margin-bottom: 0;
    }
  }
}
</style>
