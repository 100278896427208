<template>
  <a-select
    show-search
    allowClear
    v-model="form.fenceId"
    placeholder="请选择电子围栏"
    v-bind="$attrs"
    option-filter-prop="children"
    @change="handleChange"
    :filter-option="false"
    @search="v => selectSearch(v)"
  >
    <a-select-option :value="item.id" v-for="item in fenceList" :key="item.id">
      {{ item.value }}
    </a-select-option>
  </a-select>
</template>
<script>
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { getFenceList } from '@/services/smart-hat/geofence';
import { Debounce } from 'lodash-decorators';

@Component()
export default class FenceSelect extends Vue {
  @Model('bindFenceId', { type: String }) fenceId;
  @Prop({ type: Array, default: () => [] }) defaultItems; // 初始默认项

  mounted() {
    this.getFenceList();
  }
  form = {
    fenceId: undefined,
  };
  handleChange(v) {
    this.$emit('bindFenceId', v);
    this.$emit('change', v);
  }
  fenceList = [];
  async getFenceList(input = undefined) {
    this.form.fenceId = this.fenceId;
    const { records } = await getFenceList({
      current: 1,
      size: 200,
      input,
    });
    this.fenceList = records.map(item => ({
      id: item.geofenceId,
      value: item.geofenceName,
    }));
    if (this.defaultItems.length) {
      this.fenceList.unshift(...this.defaultItems);
    }
  }
  @Debounce(300)
  selectSearch(keyword) {
    this.getFenceList(keyword);
  }
}
</script>
