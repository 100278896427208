<template>
  <div :class="$style.map" id="fenceMap"></div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getZonesData } from '@/services/smart-hat/geofence';
import {
  drawCharts,
  formatToApi,
  funAMap,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';

@Component()
export default class FenceMap extends Vue {
  @Prop({ type: String }) fenceId;

  async mounted() {
    await this.initMap();
    await this.addFence();
  }

  map = null;
  AMap = null;
  center = undefined;
  async initMap() {
    if (this.map) {
      this.map = null;
      this.AMap = null;
    }
    const AMap = await funAMap();
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    this.map = new AMap.Map('fenceMap', {
      zoom: 16,
      center: this.center || undefined,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
  }

  zoneArray = [];
  chartList = [];
  async addFence() {
    if (this.chartList.length) {
      this.map.remove(this.chartList);
    }
    const array = await getZonesData({
      geofenceId: this.fenceId,
    });
    this.zoneArray = formatToApi(array);
    if (this.zoneArray.length) {
      if (this.zoneArray[0].type === 'circle') {
        this.map.setCenter(this.zoneArray[0].center);
      } else {
        this.map.setCenter(this.zoneArray[0].path[0]);
      }
    }
    this.chartList = drawCharts(this.zoneArray, this.AMap, (...args) =>
      mouseEvent.call(null, ...args, this.map),
    );
    this.map.add(this.chartList);
  }
}
</script>
<style lang="less" module>
.map {
  height: 500px;
  width: 100%;
}
</style>
