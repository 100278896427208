var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.list_ctrl},[_c('div',{class:_vm.$style.list_title},[_vm._v("任务明细")]),_c('div',{class:_vm.$style.list_filter},[_c('a-select',{class:_vm.$style.list_filter_select,attrs:{"show-search":"","placeholder":_vm.$t('hat.managementModal.event.project'),"default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null},on:{"search":function () {},"change":_vm.handleChange},model:{value:(_vm.activeCycle),callback:function ($$v) {_vm.activeCycle=$$v},expression:"activeCycle"}},_vm._l((_vm.cycleItems),function(item){return _c('a-select-option',{key:item.pkId,attrs:{"value":item.pkId}},[_vm._v(_vm._s(item.pmName))])}),1),_c('a-select',{class:_vm.$style.list_filter_select,attrs:{"show-search":"","placeholder":_vm.$t('hat.managementModal.event.project'),"default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null},on:{"search":function () {},"change":_vm.handleChange},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.inspectionStatusOptions),function(item){return _c('a-select-option',{key:item.pkId,attrs:{"value":item.pkId}},[_vm._v(_vm._s(item.pmName))])}),1),_c('a-input',{class:_vm.$style.filter_search,attrs:{"placeholder":"搜索"},on:{"blur":_vm.handleChange},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1)],1),_c('div',{class:_vm.$style.list_items},[(_vm.deviceTaskList.length)?_vm._l((_vm.deviceTaskList),function(item,index){
var _obj;
return _c('div',{key:item.deviceId,class:[
            _vm.$style.list_item,
            ( _obj = {}, _obj[_vm.$style.activeItem] = _vm.activeDeviceIdx === item.deviceId, _obj ) ],on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('div',{class:_vm.$style.user_info},[_c('div',{class:_vm.$style.userCell},[_c('x-oss-image',{class:_vm.$style.avatar,attrs:{"ossPath":item.userAvatar}}),_c('span',{class:_vm.$style.userName},[_vm._v(_vm._s(item.userName))])],1),_c('div',{class:[_vm.$style.status, _vm.$style[item.status]]},[_c('div',{class:_vm.$style.statusNum},[_vm._v(" "+_vm._s(_vm.getInspectionStatus(item.status))+" ")]),_c('div',{class:_vm.$style.splitLine}),_c('div',{class:_vm.$style.progress},[_vm._v(_vm._s(item.taskProgress)+"%")])])]),_c('div',{class:_vm.$style.progressWarps},[_c('div',{class:_vm.$style.progressItems},[_vm._l((item.subList),function(iitem){return _c('div',{key:iitem.idxTaskId,class:[_vm.$style.progressItem, _vm.$style[item.status]]},[_c('div',{class:_vm.$style.progressName},[_c('a-tooltip',{class:_vm.$style.progressNameEllipsis,attrs:{"placement":"bottom","title":iitem.taskName}},[_vm._v(_vm._s(iitem.taskNam))]),(
                      iitem.status !== 'SUCCESS' && iitem.status !== 'FAIL'
                    )?_c('x-icon',{class:_vm.$style.progressItemINIT,attrs:{"type":"tc-icon-clock"}}):_vm._e(),(iitem.status === 'SUCCESS')?_c('span',{class:[
                      _vm.$style.progressItemStatus,
                      _vm.$style.progressItemSuccess ]},[_c('x-icon',{class:_vm.$style.icon,attrs:{"type":"tc-icon-check"}})],1):_vm._e(),(iitem.status === 'FAIL')?_c('span',{class:[
                      _vm.$style.progressItemStatus,
                      _vm.$style.progressItemError ]},[_c('x-icon',{class:_vm.$style.icon,attrs:{"type":"tc-icon-close"}})],1):_vm._e()],1),_c('div',{class:_vm.$style.progressBar},[_c('div',{class:_vm.$style.progressPoint})]),_c('div',{class:_vm.$style.time},[_vm._v(" "+_vm._s(index === 0 ? _vm.dayjs(item.endTime).format('MM-DD') : '')+" "),_c('br'),_vm._v(" "+_vm._s(_vm.dayjs(iitem.taskTime).format('HH:mm'))+" ")])])}),_c('div',{class:[
                  _vm.$style.progressItem,
                  _vm.$style.endProgressItem,
                  _vm.$style[item.status] ]},[_c('div',{class:_vm.$style.progressName},[_c('a-tooltip',{class:_vm.$style.progressNameEllipsis,attrs:{"placement":"bottom","title":"结束"}},[_vm._v("结束")])],1),_c('div',{class:[_vm.$style.progressBar, _vm.$style.endProgressBar]},[_c('div',{class:_vm.$style.progressPoint})]),_c('div',{class:_vm.$style.time},[_vm._v(" "+_vm._s(_vm.dayjs(item.endTime).format('MM-DD'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.dayjs(item.endTime).format('HH:mm'))+" ")])])],2)])])}):_c('div',[_c('EmptyContent',{attrs:{"type":"list"}})],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }