<template>
  <a-form-model :rules="rules" style="height: 650px;">
    <div :class="$style.label">任务提醒</div>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item prop="newTaskReminderContent">
          <a-checkbox v-model="isNewTaskChecked"
            >新任务提醒<a-tooltip :class="$style.tipIcon">
              <div slot="title">
                当前任务发布后立刻给任务项执行设备（已去重）的当前佩戴人员下发的提醒内容。
              </div>
              <a-icon type="info-circle" /> </a-tooltip
          ></a-checkbox>
          <a-checkbox-group
            v-model="form.newTaskReminderTypeList"
            v-if="isNewTaskChecked"
          >
            <template>
              <a-checkbox value="SYSTEM">站内通知</a-checkbox>
              <a-checkbox value="SMS">短信</a-checkbox>
              <a-checkbox value="VOICE">安全帽语音</a-checkbox>
            </template>
          </a-checkbox-group>
          <a-textarea
            :rows="7"
            v-model="form.newTaskReminderContent"
            id="newTaskReminder"
          />
          <div :class="$style.tags">
            <span
              @click="
                insertVariable(
                  '{任务名称}',
                  'newTaskReminder',
                  'newTaskReminderContent',
                )
              "
              >任务名称</span
            >
            <span
              @click="
                insertVariable(
                  '{任务时段}',
                  'newTaskReminder',
                  'newTaskReminderContent',
                )
              "
              >任务时段</span
            >
            <span
              @click="
                insertVariable(
                  '{任务项个数}',
                  'newTaskReminder',
                  'newTaskReminderContent',
                )
              "
              >任务项个数</span
            >
          </div>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item prop="cycleType">
          <a-checkbox v-model="isTaskItemChecked"
            >任务项提醒<a-tooltip :class="$style.tipIcon">
              <div slot="title">
                当到达任务项开始时间后立刻下发的提醒内容，若任务项起止时间为空则不生效，可在请在任务详情>任务项提醒内容中独立设置
              </div>
              <a-icon type="info-circle" /> </a-tooltip
          ></a-checkbox>
          <a-checkbox-group
            v-model="form.taskItemReminderTypeList"
            v-if="isTaskItemChecked"
          >
            <a-checkbox value="VOICE">安全帽语音</a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item prop="newTaskReminderContent">
          <a-checkbox v-model="isTaskAddressChecked"
            >任务项地点<a-tooltip :class="$style.tipIcon">
              <div slot="title">
                在任务以及任务项的有效时间段内进入任务项地点（安全相定位/扫码）范围内的提醒内容
              </div>
              <a-icon type="info-circle" /> </a-tooltip
          ></a-checkbox>
          <a-checkbox-group
            v-model="form.taskItemAddressReminderTypeList"
            v-if="isTaskAddressChecked"
          >
            <a-checkbox value="SYSTEM">站内通知</a-checkbox>
            <a-checkbox value="SMS">短信</a-checkbox>
            <a-checkbox value="VOICE">安全帽语音</a-checkbox>
          </a-checkbox-group>
          <a-textarea
            :rows="7"
            v-model="form.taskItemAddressReminderContent"
            id="taskItemAddress"
          />
          <div :class="$style.tags">
            <span
              @click="
                insertVariable(
                  '{任务项名称}',
                  'taskItemAddress',
                  'taskItemAddressReminderContent',
                )
              "
              >任务项名称</span
            >
          </div>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item prop="newTaskReminderContent">
          <a-checkbox value="ALL" v-model="isTaskCompleteChecked"
            >任务项完成<a-tooltip :class="$style.tipIcon">
              <div slot="title">
                在任务以及任务项的有效时间段内完成任务项要求后下发的提醒内容
              </div>
              <a-icon type="info-circle" /> </a-tooltip
          ></a-checkbox>
          <a-checkbox-group
            v-model="form.taskItemCompleteReminderTypeList"
            v-if="isTaskCompleteChecked"
          >
            <a-checkbox value="SYSTEM">站内通知</a-checkbox>
            <a-checkbox value="SMS">短信</a-checkbox>
            <a-checkbox value="VOICE">安全帽语音</a-checkbox>
          </a-checkbox-group>
          <a-textarea
            :rows="7"
            v-model="form.taskItemCompleteReminderContent"
            id="taskItemComplete"
          />
          <div :class="$style.tags">
            <span
              @click="
                insertVariable(
                  '{任务项名称}',
                  'taskItemComplete',
                  'taskItemCompleteReminderContent',
                )
              "
              >任务项名称</span
            >
            <span
              @click="
                insertVariable(
                  '{任务进度}',
                  'taskItemComplete',
                  'taskItemCompleteReminderContent',
                )
              "
              >任务项进度</span
            >
          </div>
        </a-form-model-item>
      </a-col>
    </a-row>
    <div :class="$style.label" style="margin-top: 8px">任务项文件管理</div>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item prop="newTaskReminderContent">
          <span
            >匹配规则<a-tooltip :class="$style.tipIcon">
              <div slot="title">
                <p>设置任务顶匹配安全帽照片/视频的规则说明：</p>
                <p>
                  任务时间：必选，根据任务时段和任务周期来匹配期间安全帽或手机上传的照片、录像文件；
                </p>
                <p>
                  地点：可选，若安全帽照片/视频的拍摄地址在任务顶设置的地址范围内，则自动关联；
                </p>
                <p>备注：两者为and关系</p>
              </div>
              <a-icon type="info-circle" /> </a-tooltip
          ></span>
          <a-checkbox-group v-model="form.fileMatchRuleTypeList">
            <a-checkbox value="TIME">任务时间</a-checkbox>
            <a-checkbox value="ADDRESS">地点</a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item prop="newTaskReminderContent">
          <span
            >允许移除文件<a-tooltip :class="$style.tipIcon">
              <div slot="title">
                开启后允许用户在任务项有效期内移除匹配的照片或视频
              </div>
              <a-icon type="info-circle" /> </a-tooltip
          ></span>
          <a-switch
            v-model="form.fileDelete"
            checked-children="开"
            un-checked-children="关"
            default-checked
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item prop="taskShare">
          <span slot="label"
            >任务分享<a-tooltip :class="$style.tipIcon">
              <div slot="title">
                <p>
                  1、开启后允许任意用户通过分享链接查看指定周期、指定用户的任务详情！
                </p>
                <p>
                  2、若任务顶包含表单，需要开启对应表单的数据外链，否则企业外成员则无法查看！
                </p>
              </div>
              <a-icon type="info-circle" /> </a-tooltip
          ></span>
          <a-switch
            v-model="form.taskShare"
            checked-children="开"
            un-checked-children="关"
            default-checked
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="页面标题" prop="fileDelete">
          <a-input
            v-model="form.pageTitle"
            placeholder="请输入页面标题"
            :maxLength="32"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class TaskSetting extends Vue {
  @Prop({ type: Object, default: null }) formData;

  @Watch('formData')
  initFormData(newValue) {
    if (newValue) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.formData[key];
      });
      this.form.newTaskReminderContent = this.replaceContent(
        this.form.newTaskReminderContent,
      );
      this.form.taskItemAddressReminderContent = this.replaceContent(
        this.form.taskItemAddressReminderContent,
      );
      this.form.taskItemCompleteReminderContent = this.replaceContent(
        this.form.taskItemCompleteReminderContent,
      );
      this.isNewTaskChecked = this.formData.newTaskReminderTypeList.length > 0;
      this.isTaskItemChecked =
        this.formData.taskItemReminderTypeList.length > 0;
      this.isTaskAddressChecked =
        this.formData.taskItemAddressReminderTypeList.length > 0;
      this.isTaskCompleteChecked =
        this.formData.taskItemCompleteReminderTypeList.length > 0;
    }
  }

  form = {
    newTaskReminderContent: '',
    newTaskReminderTypeList: [],
    taskItemReminderTypeList: [],
    taskItemAddressReminderTypeList: [],
    taskItemAddressReminderContent: '',
    taskItemCompleteReminderTypeList: [],
    taskItemCompleteReminderContent: '',
    fileMatchRuleTypeList: [],
    fileDelete: false,
    taskShare: false,
    pageTitle: '',
  };
  isNewTaskChecked = false;
  @Watch('isNewTaskChecked')
  newTaskReminderTypeList(newVal) {
    if (!newVal) {
      this.form.newTaskReminderTypeList = [];
    }
  }
  isTaskItemChecked = false;
  @Watch('isTaskItemChecked')
  taskItemReminderTypeList(newVal) {
    if (!newVal) {
      this.form.taskItemReminderTypeList = [];
    }
  }
  isTaskAddressChecked = false;
  @Watch('isTaskAddressChecked')
  taskItemAddressReminderTypeList(newVal) {
    if (!newVal) {
      this.form.taskItemAddressReminderTypeList = [];
    }
  }
  isTaskCompleteChecked = false;
  @Watch('isTaskCompleteChecked')
  taskItemCompleteReminderTypeList(newVal) {
    if (!newVal) {
      this.form.taskItemCompleteReminderTypeList = [];
    }
  }

  rules = {
    taskName: [
      {
        required: false,
        message: '任务名称不能为空',
      },
    ],
    idxGeofenceId: [
      {
        required: false,
        message: '地点不能为空',
      },
    ],
  };

  // 向输入框光标位置插入文本
  async insertVariable(value, id, formContent) {
    const myField = document.getElementById(id);
    if (myField.selectionStart || myField.selectionStart === 0) {
      let startPos = myField.selectionStart;
      let endPos = myField.selectionEnd;
      this.form[formContent] =
        myField.value.substring(0, startPos) +
        value +
        myField.value.substring(endPos, myField.value.length);
      await this.$nextTick();
      myField.focus();
      myField.setSelectionRange(endPos + value.length, endPos + value.length);
    } else {
      this.form[formContent] = value;
    }
  }

  replaceContent(content) {
    return content
      .replace(/TASK_NAME/g, '{任务项名称}')
      .replace(/TASK_TIME/g, '{任务项时段}')
      .replace(/TASK_NUM/g, '{任务项个数}')
      .replace(/TASK_DESC/g, '{任务项说明}')
      .replace(/INSPECTION_SCHEDULE/g, '{任务进度}')
      .replace(/INSPECTION_TIME/g, '{任务时段}')
      .replace(/INSPECTION_NAME/g, '{任务名称}')
      .replace(/LOCATION_NAME/g, '{地点}');
  }
}
</script>

<style lang="less" module>
.tags {
  span {
    cursor: pointer;
    font-size: 12px;
    background-color: var(--primary-fade-20);
    padding: 3px 6px;
    border-radius: 6px;
    margin-right: 10px;
  }
}
.tipIcon {
  margin: 0 6px;
  color: var(--font-info);
}
.label {
  color: var(--font-info);
}
</style>
