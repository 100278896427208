<template>
  <i
    v-if="category"
    :class="['x-tree-select--category', `category-${category}`]"
  >
    {{
      $t(`application.form.category.${FormCategoryTypeName[category]}`) ||
        '未定义类型'
    }}
  </i>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormCategoryTypeName } from '@/enum/form';

@Component()
export default class FormCategoryTag extends Vue {
  @Prop() category;

  get FormCategoryTypeName() {
    return FormCategoryTypeName;
  }
}
</script>
<style lang="less">
.x-tree-select--category {
  border-radius: 17px;
  color: #ffffff;
  padding: 0 10px;
  height: 22px;
  line-height: 22px;
  font-style: normal;
  font-size: 14px;
  &.category-3,
  &.category-4 {
    background: #00db8e;
  }
  &.category-5 {
    background: #fab30b;
  }
  &.category-6 {
    background: #8f65e7;
  }
  &.category-8 {
    background: rgb(245, 215, 20);
  }
}
</style>
