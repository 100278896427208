<template>
  <div :class="$style.manageBox" v-if="!isDetail">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">{{ $path('/hat/task') }}</span>
      </template>
      <div :class="$style.manageContent" ref="content" slot="content">
        <flex-col-content>
          <div :class="$style.form">
            <a-form-model layout="inline">
              <a-form-model-item>
                <a-select
                  style="width: 200px"
                  v-model="searchParams.isPartIn"
                  :options="roleSelectOption"
                  @change="() => handleSearch()"
                />
              </a-form-model-item>
              <a-form-model-item>
                <a-select
                  style="width: 200px"
                  v-model="searchParams.inspectionStatus"
                  :options="statusSelectOption"
                  placeholder="全部状态"
                  @change="() => handleSearch()"
                />
              </a-form-model-item>
              <a-form-model-item>
                <a-input
                  style="width: 200px"
                  v-model="searchParams.input"
                  @input.stop="() => handleSearch()"
                  placeholder="任务/任务项/说明"
                >
                  <a-icon slot="prefix" type="search" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item style="margin-left: auto">
                <a-button type="primary" size="small" @click="handleAdd()"
                  >新建任务</a-button
                >
              </a-form-model-item>
            </a-form-model>
          </div>

          <template #footer>
            <a-table
              :class="$style.wrapper"
              showSelection
              :columns="columns"
              :data-source="tableData"
              :pagination="pagination"
              :scroll="{ x: 2000 }"
              :row-key="record => record.inspectionId"
              :loading="loading"
              @change="handlePagination"
            >
              <a-tooltip
                placement="bottom"
                slot="inspectionStatusTitle"
                title="若是按周/月循环，则只展示最新一个周期的状态。"
              >
                <span style="margin-right: 3px">状态</span>
                <a-icon type="info-circle" />
              </a-tooltip>
              <a-tooltip
                placement="bottom"
                slot="operationNumTitle"
                title="若同一设备（当前佩戴人）参与多个任务项，均只按1统计。"
              >
                <span style="margin-right: 3px">执行设备（人）</span>
                <a-icon type="info-circle" />
              </a-tooltip>
              <a-tooltip
                placement="bottom"
                slot="inspectionScheduleTitle"
                title="任务进度可能有延迟，如需获取最新进度可点击刷新查询最新数据。"
              >
                <span style="margin-right: 3px">任务进度</span>
                <a-icon type="info-circle" />
              </a-tooltip>
              <template slot="action" slot-scope="_, record">
                <div :class="$style.buttonGroups">
                  <a
                    v-if="btnRoleDelete(record)"
                    :class="[$style.button]"
                    @click.stop="handleDelete(record)"
                    >删除</a
                  >
                  <a
                    v-if="btnRoleUpdate(record)"
                    :class="[$style.button]"
                    @click.stop="() => handleEdit(record)"
                    >编辑</a
                  >
                  <a
                    v-if="btnRoleCancel(record)"
                    :class="[$style.button]"
                    @click.stop="handleCancelTask(record)"
                    >结束</a
                  >
                  <a
                    v-if="btnRoleTaskDetail(record)"
                    :class="[$style.button]"
                    @click.stop="handleDetailed(record)"
                    >明细</a
                  >
                  <a
                    :class="[$style.button]"
                    v-if="btnRoleDetail(record)"
                    @click.stop="handleDetail(record)"
                    >详情</a
                  >
                  <a
                    :class="[$style.button]"
                    v-if="btnRoleCopy(record)"
                    @click="handleCopy(record)"
                    >复制</a
                  >
                  <a
                    :class="[$style.button]"
                    v-if="btnRoleTaskCode(record)"
                    @click="handleQrcode(record)"
                    >任务码</a
                  >
                </div>
              </template>
            </a-table>
          </template>
        </flex-col-content>
      </div>
    </layout-content>
  </div>
  <task-detail
    @backList="handleGoList"
    :detail-item="activeDetailItem"
    v-else
  ></task-detail>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import Tip from '@/components/tip';
import { LayoutContent } from '@triascloud/x-blocks';
import { createFormModal, createModal } from '@triascloud/x-components';
import { Debounce } from 'lodash-decorators';
import AddTask from './add-task/index.vue';
import FlexColContent from '@/views/hat/components/flex-col-content';
import {
  cancelInspection,
  deleteInspection,
  getPage,
} from '@/services/smart-hat/inspection.js';
import TaskDetail from '@/views/hat/task/detail/index.vue';

const ENUM_STATUS = {
  /** @name 草稿 */
  DRAFT: 'DRAFT',
  /** @name 未开始 */
  UN_START: 'UN_START',
  /** @name 进行中 */
  UNDER_WAY: 'UNDER_WAY',
  /** @name 已结束 */
  FINISH: 'FINISH',
};

const KEY_STATUS = {
  [ENUM_STATUS.DRAFT]: '草稿',
  [ENUM_STATUS.UN_START]: '未开始',
  [ENUM_STATUS.UNDER_WAY]: '进行中',
  [ENUM_STATUS.FINISH]: '已结束',
};
const ENUM_CYCLE = {
  /** @name 按周循环 */
  WEEK: 'WEEK',
  /** @name 按月循环 */
  MONTH: 'MONTH',
  /** @name 固定时段 */
  DESIGNATED: 'DESIGNATED',
};
const KEY_CYCLE_STATUS = {
  [ENUM_CYCLE.WEEK]: '按周循环',
  [ENUM_CYCLE.MONTH]: '按月循环',
  [ENUM_CYCLE.DESIGNATED]: '固定时段',
};

@Component({
  components: {
    TaskDetail,
    LayoutContent,
    FlexColContent,
    AddTask,
  },
})
export default class Task extends Vue {
  isDetail = false;
  activeDetailItem = null;
  async mounted() {
    await this.fetchTable();
  }

  handleGoList() {
    this.isDetail = false;
    this.activeDetailItem = {};
  }

  btnRoleDelete(status) {
    if (status === ENUM_STATUS.DRAFT || status === ENUM_STATUS.FINISH) {
      return true;
    } else {
      return false;
    }
  }
  btnRoleUpdate({ inspectionStatus: status }) {
    if (status === ENUM_STATUS.DRAFT) {
      return true;
    } else {
      return false;
    }
  }
  btnRoleCopy({ inspectionStatus: status }) {
    if (status === ENUM_STATUS.DRAFT || status === ENUM_STATUS.FINISH) {
      return true;
    } else {
      return false;
    }
  }
  btnRoleDetail({ inspectionStatus: status }) {
    if (
      status === ENUM_STATUS.UN_START ||
      status === ENUM_STATUS.UNDER_WAY ||
      status === ENUM_STATUS.FINISH
    ) {
      return true;
    } else {
      return false;
    }
  }
  btnRoleCancel({ inspectionStatus: status }) {
    if (status === ENUM_STATUS.UN_START || status === ENUM_STATUS.UNDER_WAY) {
      return true;
    } else {
      return false;
    }
  }
  btnRoleTaskDetail({ inspectionStatus: status }) {
    if (
      status === ENUM_STATUS.UN_START ||
      status === ENUM_STATUS.UNDER_WAY ||
      status === ENUM_STATUS.FINISH
    ) {
      return true;
    } else {
      return false;
    }
  }
  btnRoleTaskCode({ inspectionStatus: status, isShowCode }) {
    if (
      isShowCode &&
      (status === ENUM_STATUS.UN_START || status === ENUM_STATUS.UNDER_WAY)
    ) {
      return true;
    } else {
      return false;
    }
  }

  loading = false;
  async fetchTable() {
    this.loading = true;
    let params = {
      current: this.pagination.current,
      size: this.pagination.size,
      isPartIn: this.searchParams.isPartIn ? true : false,
    };
    if (this.searchParams.inspectionStatus) {
      params.inspectionStatus = this.searchParams.inspectionStatus;
    }
    if (this.searchParams.input) {
      params.input = this.searchParams.input;
    }
    const result = await getPage(params);
    this.tableData = result.records;
    this.pagination.total = result.total;
    this.loading = false;
  }

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: () => this.sizeTotal(),
  };
  sizeTotal() {
    return `共${this.pagination.total}条`;
  }
  handlePagination(data) {
    this.pagination = data;
    this.fetchTable();
  }

  tableData = [];

  cycleFormat(type, txt) {
    if (type === ENUM_CYCLE.DESIGNATED) {
      const fn = t => dayjs(t).format('YYYY-MM-DD');
      if (txt.length > 1) {
        return `${fn(+txt[0])}至${fn(+txt[1])}`;
      } else {
        return `${fn(+txt[0])}`;
      }
    } else if (type === ENUM_CYCLE.WEEK) {
      let str = ``;
      txt.forEach(v => {
        const KEY_WEEK = {
          1: '周一',
          2: '周二',
          3: '周三',
          4: '周四',
          5: '周五',
          6: '周六',
          7: '周日',
        };
        str += `${KEY_WEEK[v]} `;
      });
      return `每周 ${str}`;
    } else {
      let str = ``;
      txt.forEach(v => {
        str += `${v}号 `;
      });
      return `每月 ${str}`;
    }
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('hat.clockRecord.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        dataIndex: 'inspectionName',
        title: '名称',
        width: 100,
        ellipsis: true,
      },
      {
        align: 'left',
        title: '任务时段',
        // width: 300,
        ellipsis: true,
        dataIndex: 'cycleType',
        customRender: (text, record) => {
          return (
            <div style="display:flex;flex-direction:column;padding:4px 0;">
              <span style="height:14px;line-height:14px;margin-bottom:8px;">
                {KEY_CYCLE_STATUS[text]}
              </span>
              <span style="height:22px;line-height:22px;">
                {this.cycleFormat(text, record.cycleTimeList)}
              </span>
            </div>
          );
        },
      },
      {
        dataIndex: 'inspectionStatus',
        slots: { title: 'inspectionStatusTitle' },
        customRender: text => KEY_STATUS[text],
        // width: 100,
        ellipsis: true,
      },
      {
        title: '任务项',
        dataIndex: 'taskNum',
        width: 80,
        ellipsis: true,
      },
      {
        // 执行设备（人）
        dataIndex: 'operationNum',
        slots: { title: 'operationNumTitle' },
        ellipsis: true,
        width: 150,
        customRender: (txt, record) => {
          return (
            <div>
              {txt}&nbsp;
              {record.isPartIn ? (
                <span class={this.$style.tagActive}>我参与的</span>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        // 任务进度
        dataIndex: 'inspectionSchedule',
        slots: { title: 'inspectionScheduleTitle' },
        ellipsis: true,
        width: 150,
        customRender: (txt, record) => {
          return (
            <div>
              {this.inspectionScheduleTxt(txt, record.inspectionStatus)}
              {record.inspectionStatus === ENUM_STATUS.UNDER_WAY ? (
                <a-icon
                  type="reload"
                  style="color: var(--font-active);margin-left: 6px;"
                  onClick={this.reloadInspection(record)}
                />
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        title: '最近编辑',
        dataIndex: 'updateName',
        width: 120,
        ellipsis: true,
      },
      {
        title: '最近编辑时间',
        dataIndex: 'updateTime',
        customRender: text => this.$moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }

  inspectionScheduleTxt(txt, status) {
    if (status === ENUM_STATUS.DRAFT) {
      return '-';
    } else {
      if (txt >= 0) {
        return txt + '%';
      } else {
        return '-';
      }
    }
  }

  reloadInspection(record) {
    console.log(record);
  }

  roleSelectOption = [
    {
      value: 0,
      label: '全部任务',
    },
    {
      value: 1,
      label: '我参与的',
    },
  ];

  statusSelectOption = [
    {
      value: '',
      label: '全部状态',
    },
    {
      value: 'DRAFT',
      label: '草稿',
    },
    {
      value: 'UN_START',
      label: '未开始',
    },
    {
      value: 'UNDER_WAY',
      label: '进行中',
    },
    {
      value: 'FINISH',
      label: '已结束',
    },
  ];
  searchParams = {
    input: '',
    inspectionStatus: undefined,
    isPartIn: 0,
  };
  @Debounce(500)
  handleSearch() {
    this.fetchTable();
  }
  handleQrcode(record) {
    createModal(() => <TaskCode detail={record} />, {
      width: 345,
      title: '任务码',
    });
  }
  handleDetail(record) {
    this.modal = createModal(
      () => (
        <AddTask
          operationType={'detail'}
          taskId={record.inspectionId}
          onCancel={this.modal.handleClose}
        />
      ),
      {
        width: 860,
        title: '任务详情',
        maskClosable: false,
      },
    );
  }
  async handleCancelTask(record) {
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>确定提前结束{record.inspectionName}？请注意以下事项：</span>
            </template>
            <template slot="subTxt">
              <div style="text-align: left;">
                <p>1、设备端可能继续播报任务提醒（即使任务已结束）；</p>
                <p>2、任务项停止关联照片/视频（即使符合任务要求）；</p>
                <p>3、任务项的进度将被冻结并停止更新;</p>
                <p>4、若任务正在进行中，将无法查看设备的实时位置；</p>
                <p>5、任务明细可正常查看，且不会影响有效期内的分享链接；</p>
              </div>
            </template>
          </Tip>
        ),
        {
          width: '442px',
          title: '结束任务',
          onOk: async () => {
            try {
              await cancelInspection({
                inspectionId: record.inspectionId,
              });
              await this.$message.success(this.$t('camera.removeSuccessCode'));
              this.fetchTable();
            } catch {
              return false;
            }
          },
        },
      );
    } catch {
      return false;
    }
  }
  checked = false;
  async handleDelete(record) {
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>确定移除该设备？</span>
            </template>
            <template slot="subTxt">
              <span>移除后，当前企业将不能再查看和管理该设备</span>
            </template>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('common.tips.prompt'),
          onOk: async () => {
            try {
              await deleteInspection(record.inspectionId);
              await this.$message.success(this.$t('camera.removeSuccessCode'));
              this.fetchTable();
            } catch {
              return false;
            }
          },
        },
      );
    } catch {
      return false;
    }
  }
  onChange() {
    this.checked = !this.checked;
  }
  handleDetailed(record) {
    this.isDetail = true;
    this.activeDetailItem = record;
  }
  modal = null;
  handleEdit(record) {
    this.modal = createModal(
      () => (
        <AddTask
          operationType={'edit'}
          taskId={record.inspectionId}
          onSubmit={this.onSubmit}
          onCancel={this.modal.handleClose}
        />
      ),
      {
        width: 860,
        title: '编辑任务',
        maskClosable: false,
      },
    );
  }
  handleCopy(record) {
    this.modal = createModal(
      () => (
        <AddTask
          operationType={'copy'}
          taskId={record.inspectionId}
          onSubmit={this.onSubmit}
          onCancel={this.modal.handleClose}
        />
      ),
      {
        width: 860,
        title: '新建任务',
        maskClosable: false,
      },
    );
  }
  handleAdd() {
    this.modal = createModal(
      () => (
        <AddTask
          operationType={'add'}
          onSubmit={this.onSubmit}
          onCancel={this.modal.handleClose}
        />
      ),
      {
        width: 860,
        title: '新建任务',
        maskClosable: false,
      },
    );
  }
  onSubmit() {
    this.modal.handleClose();
    this.fetchTable();
  }
}
</script>
<style lang="less" module>
.wrapper {
  position: relative;
  :global {
    .ant-table-tbody > tr > td {
      vertical-align: middle;
    }
    .ant-pagination-total-text {
      float: left;
    }
  }
}
.buttonGroups {
  display: flex;
  gap: 20px;
}
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  .title {
    font-size: 16px;
  }
  .manageContent {
    padding: 20px 28px;
    min-width: 850px;
    // height: 100%;
    // .table {
    //   height: 94%;
    //   padding-bottom: 10px;
    // }
    :global {
      .ant-table-wrapper .ant-spin-container .ant-table-body,
      .ant-table-wrapper .ant-table-scroll .ant-table-body {
        flex: 1;
        overflow: auto !important;
        max-height: none !important;
      }
    }
  }
  .form {
    width: 100%;
    padding-bottom: 15px;
    :global(.ant-form) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .buttonGroup {
    display: flex;
    width: max-content;
    gap: 20px;
  }
}
.tagActive {
  background-color: var(--font-active);
  color: #fff;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 12px;
  height: 24px;
  line-height: 18px;
}
</style>
